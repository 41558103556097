interface Props {
  totalCortesias: number;
}

export default function CardCortesias({ totalCortesias }: Props) {
  return (
    <div className="col-12 card-cinza-escuro p-3">
      <div className="d-flex flex-wrap-reverse align-items-center justify-content-between h-100">
        <div className="d-flex flex-column justify-content-center">
          <p className="text-500-black-18 m-0">Cortesias</p>
          <p className="text-number-card-home m-0 text-break text-black fs-2">
            {totalCortesias.toString().length < 2
              ? "0" + totalCortesias
              : totalCortesias.toString()}
          </p>
        </div>
        <SvgIconCortesia />
      </div>
    </div>
  );
}

const SvgIconCortesia = (): JSX.Element => (
  <svg
    className="my-2"
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="28" cy="28" r="28" fill="#E36790" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1126 19C18.4981 19 18 19.4981 18 20.1126V29.384C18 29.6793 18.1174 29.9625 18.3263 30.1712L26.2897 38.1253C26.5652 38.4011 26.8924 38.6199 27.2525 38.7692C27.6127 38.9185 27.9988 38.9954 28.3887 38.9954C28.7786 38.9954 29.1646 38.9185 29.5248 38.7692C29.885 38.6199 30.2122 38.4011 30.4877 38.1252L37.1349 31.478L36.3482 30.6913L37.1373 31.4757C37.6898 30.9198 38 30.1678 38 29.384C38 28.6002 37.6898 27.8483 37.1373 27.2924L29.1707 19.3259C28.9621 19.1172 28.6791 19 28.384 19H19.1126ZM35.56 29.9061L35.5592 29.9069L28.9134 36.5527C28.8445 36.6216 28.7627 36.6763 28.6727 36.7137C28.5827 36.751 28.4861 36.7702 28.3887 36.7702C28.2912 36.7702 28.1947 36.751 28.1046 36.7137C28.0146 36.6763 27.9328 36.6216 27.8639 36.5527L20.2251 28.9228V21.2251H27.9232L35.5592 28.8611L35.56 28.8619C35.6976 29.0008 35.7748 29.1885 35.7748 29.384C35.7748 29.5796 35.6976 29.7672 35.56 29.9061ZM23.7483 23.5483C23.0856 23.5483 22.5483 24.0856 22.5483 24.7483C22.5483 25.4111 23.0856 25.9483 23.7483 25.9483H23.7583C24.4211 25.9483 24.9583 25.4111 24.9583 24.7483C24.9583 24.0856 24.4211 23.5483 23.7583 23.5483H23.7483Z"
      fill="white"
    />
  </svg>
);
