import { useEffect, useState } from "react";
import BreadCrumb, { Page } from "../../Components/Breadcrumb";
import MainLayout from "../../Components/Layouts/Main";
import WhiteContainer from "../../Components/WhiteContainer";
import { UsuarioModel } from "../../Interfaces/Models";
import ListagemUsuarios from "./Components/ListagemUsuarios";
import SkeletonListagem from "../../Components/SkeletonListagem";
import DarkGrayCard from "../../Components/DarkGrayCard";
import { ListServiceResult } from "../../Interfaces";
import AxiosClient from "../../Services/AxiosClient";
import AxiosErrorHandler from "../../Services/AxiosErrorHandler";
import toast from "react-hot-toast";
import FiltroUsuarios from "./Components/FiltroUsuarios";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "dashboard",
  },
  {
    link: "/usuarios",
    name: "Usuários",
  },
];

export default function Usuarios() {
  const [loading, setLoading] = useState<boolean>(false);
  const [usuarios, setUsuarios] = useState<UsuarioModel[]>([]);
  const [pagina, setPagina] = useState<number>(1);
  const [possuiMaisUsuarios, setPossuiMaisUsuarios] = useState<boolean>(false);
  const [filtroBusca, setFiltroBusca] = useState<string>("");
  const [filtrosAplicados, setFiltrosAplicados] = useState<string[]>([]);

  const handleFiltro = () => {
    setFiltrosAplicados([]);

    if (filtroBusca) {
      setFiltrosAplicados((prev) => [...prev, filtroBusca]);
    }

    setPagina(1);
    setUsuarios([]);
  };

  const removerFiltro = (filtro: string): void => {
    if (filtro === filtroBusca) {
      setFiltroBusca("");
    }

    setFiltrosAplicados((prev) => prev.filter((f) => f !== filtro));

    setUsuarios([]);
  };

  const getRequestPathWIthQueryParams = (): string => {
    let url: string = `/usuarios`;
    url += `?pagina=${pagina}`;
    url += "&paginaTamanho=10";

    if (filtroBusca) {
      url += `&searchString=${filtroBusca}`;
    }

    return url;
  };

  const getUsuarios = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<UsuarioModel>>(
      getRequestPathWIthQueryParams()
    )
      .then(({ data }) => {
        setUsuarios((prev) => [...prev, ...(data?.data ?? [])]);
        setPossuiMaisUsuarios(data.pages! > data.page!);
      })
      .catch((error) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  // * Infinite scroll * //
  useEffect(() => {
    function handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (
        !loading &&
        possuiMaisUsuarios &&
        scrollTop + clientHeight >= scrollHeight - 500
      ) {
        setLoading(true);
        setPagina(pagina + 1);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, pagina, possuiMaisUsuarios]);

  useEffect(() => {
    getUsuarios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, filtrosAplicados]);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Usuários</p>
          <FiltroUsuarios
            filtroBusca={filtroBusca}
            setFiltroBusca={setFiltroBusca}
            handleFiltro={handleFiltro}
          />
        </div>
      </WhiteContainer>

      <WhiteContainer
        rowMarginBottom={4}
        containerPadding={4}
        containerWidth={12}
      >
        {filtrosAplicados.length > 0 && (
          <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
            {filtrosAplicados.map((filtro, index) => (
              <div className="me-3 mb-2" key={index}>
                <div
                  className="d-flex flex-wrap justify-content-center align-items-center px-4"
                  style={{ borderRadius: 50, background: "#F9F8F8" }}
                >
                  <p className="text-400-black-16 m-0 me-2 my-2">Filtro:</p>
                  <p className="text-400-black-16 m-0 me-2 my-2 text-break">
                    {filtro}
                  </p>
                  <button
                    className="btn-close my-2"
                    onClick={() => removerFiltro(filtro)}
                  ></button>
                </div>
              </div>
            ))}
          </div>
        )}

        {usuarios.length > 0 && <ListagemUsuarios usuarios={usuarios} />}

        {loading && <SkeletonListagem />}

        {!loading && usuarios.length === 0 && (
          <DarkGrayCard message="Você ainda não tem usuários por aqui!" />
        )}
      </WhiteContainer>
    </MainLayout>
  );
}
