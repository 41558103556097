import { useForm } from "react-hook-form";
import Input from "../../../../Components/Inputs/Input";
import WhiteContainer from "../../../../Components/WhiteContainer";
import { useState } from "react";
import Button from "../../../../Components/Button";
import { AddIcon } from "../../../../Components/Icons";
import ValidationError from "../../../../Components/ValidationError";
import toast from "react-hot-toast";
import AxiosClient from "../../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { useNavigate } from "react-router-dom";

interface Props {
  eventoId: string;
}

interface CoprodutorPostBody {
  coProdutorEmail: string;
}

export default function CadastrarCoprodutor({ eventoId }: Props) {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CoprodutorPostBody>({
    defaultValues: {
      coProdutorEmail: "",
    },
  });

  const [loading, setLoading] = useState<boolean>(false);

  const cadastrarCoprodutor = async (
    data: CoprodutorPostBody
  ): Promise<void> => {
    setLoading(true);

    toast.promise(
      AxiosClient.post(`/eventos/${eventoId}/coprodutores`, data)
        .then(() => navigate(0)) // temp: até implementar context
        .finally(() => setLoading(false)),
      {
        loading: "Cadastrando coprodutor...",
        success: "Coprodutor adicionado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  return (
    <WhiteContainer containerPadding={4}>
      <div className="col-xl-12">
        <p className="title-h1 text-black mb-4">Adicionar novo coprodutor</p>
      </div>

      <form onSubmit={handleSubmit(cadastrarCoprodutor)}>
        <div className="row g-3">
          <div className="col-lg-8">
            <p className="text-500-dark-18">E-mail do coprodutor</p>

            <Input
              type="email"
              name="coProdutorEmail"
              control={control}
              errors={errors}
              validation={{
                required: "Informe o email do coprodutor",
                validate: {
                  email: (value: string) =>
                    (value.includes("@") && value.includes(".")) ||
                    `O e-mail '${value}' é inválido. Insira um e-mail corretamente.`,
                },
              }}
              placeholder="E-mail"
            />
            {errors.coProdutorEmail && ValidationError(errors.coProdutorEmail)}
          </div>

          <div className="col-lg-12 d-flex justify-content-end">
            <Button
              type="submit"
              disabled={loading || !!errors.coProdutorEmail}
              text="Adicionar"
              icon={<AddIcon />}
            />
          </div>
        </div>
      </form>
    </WhiteContainer>
  );
}
