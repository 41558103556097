import Button from "../../../../Components/Button";
import { DeleteIcon } from "../../../../Components/Icons";
import { ProdutorEventoModel } from "../../../../Interfaces/Models";

interface Props {
  coprodutor: ProdutorEventoModel;
  abrirModalExcluirCoprodutor: (coprodutor: ProdutorEventoModel) => void;
}

export default function BotoesListagemCoprodutores({
  coprodutor,
  abrirModalExcluirCoprodutor,
}: Props) {
  return (
    <div className="d-flex flex-wrap justify-content-center justify-content-md-evenly align-items-center">
      <Button
        className="m-2 px-3 rounded-pill w-100 h-100"
        variant="light"
        text="Excluir"
        height={24}
        width={150}
        icon={<DeleteIcon />}
        onClick={() => abrirModalExcluirCoprodutor(coprodutor)}
      />
    </div>
  );
}
