import { useParams } from "react-router";
import MainLayout from "../../../Components/Layouts/Main";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import WhiteContainer from "../../../Components/WhiteContainer";
import DarkGrayCard from "../../../Components/DarkGrayCard";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CategoryScale, Chart as ChartJS } from "chart.js/auto";
import { FormatarCentavosParaReais, WhiteLabel } from "../../../Utils";
import AxiosClient from "../../../Services/AxiosClient";
import { ServiceResult } from "../../../Interfaces";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import toast from "react-hot-toast";
import Loading from "../../../Components/Loading";
import CardPedidos from "./Components/CardPedidos";
import CardPendentes from "./Components/CardPendentes";
import CardCortesias from "./Components/CardCortesias";
import CardDisponivel from "./Components/CardDisponivel";
import { useNavigate } from "react-router-dom";
import { EstatisticasEventoModel } from "../../../Interfaces/Models";

enum Granularidade {
  Dia = "Dia",
  Semana = "Semana",
  Todos = "Todos",
}

export default function Estatisticas() {
  const { eventoId } = useParams();
  const navigate = useNavigate();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/estatísticas`,
      name: "Estatísticas",
    },
  ];

  const [loading, setLoading] = useState<boolean>(true);
  const [estatisticas, setEstatisticas] =
    useState<EstatisticasEventoModel | null>(null);
  const [granularidade, setGranularidade] = useState<Granularidade>(
    Granularidade.Todos,
  );

  const [grafico, setGrafico] = useState<any>();

  const obterEstatisticas = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<EstatisticasEventoModel>>(
      `/eventos/${eventoId}/estatisticas`,
    )
      .then(({ data: { data } }) => {
        const estatisticas = data as EstatisticasEventoModel;
        setEstatisticas(estatisticas);
        alterarGraficoGranularidade(estatisticas);
      })
      .catch((error) => {
        navigate("/eventos");
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const alterarGraficoGranularidade = (
    estatisticas: EstatisticasEventoModel,
  ) => {
    const { grafico } = estatisticas;
    let labels = Object.keys(grafico || {});
    const dateNow = new Date();

    switch (granularidade) {
      case Granularidade.Dia:
        labels = labels.filter((label) => {
          const data = new Date(label);
          return (
            data.getUTCDate() === dateNow.getDate() &&
            data.getUTCMonth() === dateNow.getMonth() &&
            data.getUTCFullYear() === dateNow.getFullYear()
          );
        });
        break;

      case Granularidade.Semana:
        labels = labels.filter((label) => {
          const data = new Date(label);
          return (
            data.getUTCDate() >= dateNow.getDate() - 7 &&
            data.getUTCDate() <= dateNow.getDate() &&
            data.getUTCMonth() === dateNow.getMonth() &&
            data.getUTCFullYear() === dateNow.getFullYear()
          );
        });
        break;

      default:
        break;
    }

    if (labels.length === 0) {
      setGrafico({
        labels: [],
        datasets: [
          {
            label: "Ingressos vendidos",
            data: [],
            borderColor: "#2b1479",
            backgroundColor: "#2b1479",
            CategoryScale,
          },
          {
            label: "Receita (R$)",
            data: [],
            borderColor: WhiteLabel().colorPrimary,
            backgroundColor: WhiteLabel().colorPrimary,
            CategoryScale,
          },
        ],
      });

      return;
    }

    setGrafico({
      labels: labels,
      datasets: [
        {
          label: "Ingressos vendidos",
          data: labels.map((data) => grafico[data].ingressos),
          borderColor: "#2b1479",
          backgroundColor: "#2b1479",
          CategoryScale,
        },
        {
          label: "Receita (R$)",
          data: labels.map((data) => grafico[data].receitaCentavos / 100),
          borderColor: WhiteLabel().colorPrimary,
          backgroundColor: WhiteLabel().colorPrimary,
          CategoryScale,
        },
      ],
    });
  };

  const obterTituloGrafico = () => {
    switch (granularidade) {
      case Granularidade.Dia:
        return "Vendas de hoje";
      case Granularidade.Semana:
        return "Vendas nos últimos 7 dias";
      default:
        return "Todas as vendas";
    }
  };

  useEffect(() => {
    alterarGraficoGranularidade(
      estatisticas || ({} as EstatisticasEventoModel),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [granularidade]);

  useEffect(() => {
    obterEstatisticas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Export CSV

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Estatísticas</p>

          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <button
              type="button"
              className={`bc-btn bc-btn-primary px-3 py-2 w-00 h-00 disabled`}
              disabled={true}
            >
              Exportar CSV
            </button>
          </div>
        </div>
      </WhiteContainer>

      {loading && <Loading container="50vh" />}

      {!loading && (
        <>
          {estatisticas && (
            <>
              <div className="row g-3 mb-3">
                <div className="col-lg-4">
                  <div className="shadow-sm bg-white p-3 h-100">
                    <p className="text-500-black-18 m-0">Ingressos emitidos</p>
                    <p className="text-number-card-home m-0 text-break text-black">
                      {estatisticas.ingressosECortesiasEmitidos}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="shadow-sm bg-white p-3 h-100">
                    <p className="text-500-black-18 m-0">Receita líquida</p>
                    <p className="text-number-card-home m-0 text-break text-black">
                      {FormatarCentavosParaReais(
                        estatisticas.receitaLiquidaCentavos,
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="shadow-sm bg-white p-3 h-100">
                    <p className="text-500-black-18 m-0">Ticket médio</p>
                    <p className="text-number-card-home m-0 text-break text-black">
                      {FormatarCentavosParaReais(
                        estatisticas.ticketMedioCentavos,
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row g-3 mb-4">
                <div className="col-xl-6">
                  <div className="shadow-sm bg-white p-3 h-100">
                    <div className="row g-3 h-100">
                      <div className="col-lg-4">
                        <CardPedidos totalVendas={estatisticas.totalVendas} />
                      </div>
                      <div className="col-lg-8">
                        <div className="row g-3 h-100 m-0">
                          <CardPendentes
                            totalPendentes={estatisticas.totalPendentes}
                          />

                          <CardCortesias
                            totalCortesias={estatisticas.totalCortesias}
                          />

                          <CardDisponivel
                            totalDisponivel={estatisticas.totalDisponivel}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-none d-sm-block col-xl-6">
                  <div
                    className="shadow-sm bg-white p-3 w-100 h-100"
                    style={{ minHeight: "50vh" }}
                  >
                    <div className="d-flex flex-column flex-lg-row justify-content-between mb-3 me-lg-4">
                      <div className="d-flex flex-column me-3">
                        <p className="text-400-black-24 mb-1">
                          Vendas de ingressos
                        </p>
                        <p className="text-500-dark-18 m-0">
                          Relatório dos ingressos do evento
                        </p>
                      </div>
                      <div className="d-flex flex-column card-cinza-escuro p-3 pe-5">
                        <div className="form-check form-switch d-flex align-items-center ps-5">
                          <input
                            className="form-check-input mb-1 cursor-pointer"
                            style={{ width: "3.3em", height: "1.7em" }}
                            type="checkbox"
                            role="switch"
                            id="semana"
                            name="granularidade"
                            checked={granularidade === Granularidade.Semana}
                            onChange={() => {
                              setGranularidade((prev) => {
                                if (prev === Granularidade.Semana) {
                                  return Granularidade.Todos;
                                }
                                return Granularidade.Semana;
                              });
                              alterarGraficoGranularidade(estatisticas);
                            }}
                          />
                          <label
                            className="form-check-label ps-2 cursor-pointer"
                            htmlFor="semana"
                          >
                            Semana
                          </label>
                        </div>
                        <div className="form-check form-switch d-flex align-items-center ps-5">
                          <input
                            className="form-check-input mb-1 cursor-pointer"
                            style={{ width: "3.3em", height: "1.7em" }}
                            type="checkbox"
                            role="switch"
                            id="dia"
                            name="granularidade"
                            checked={granularidade === Granularidade.Dia}
                            onChange={() => {
                              setGranularidade((prev) => {
                                if (prev === Granularidade.Dia) {
                                  return Granularidade.Todos;
                                }
                                return Granularidade.Dia;
                              });
                              alterarGraficoGranularidade(estatisticas);
                            }}
                          />
                          <label
                            className="form-check-label ps-2 cursor-pointer"
                            htmlFor="dia"
                          >
                            Dia
                          </label>
                        </div>
                      </div>
                    </div>
                    {grafico ? (
                      <Bar
                        data={grafico}
                        options={{
                          responsive: true,
                          plugins: {
                            title: {
                              display: true,
                              text: obterTituloGrafico(),
                              font: {
                                size: 18,
                              },
                            },
                          },
                        }}
                      />
                    ) : (
                      <DarkGrayCard message="Dados insuficientes para gerar o gráfico." />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {!estatisticas && (
            <WhiteContainer
              rowMarginBottom={4}
              containerPadding={4}
              containerWidth={12}
            >
              <DarkGrayCard message="Você ainda não tem estatísticas por aqui!" />
            </WhiteContainer>
          )}
        </>
      )}
    </MainLayout>
  );
}
