import { useForm } from "react-hook-form";
import BreadCrumb, { Page } from "../../../../Components/Breadcrumb";
import Input from "../../../../Components/Inputs/Input";
import MainLayout from "../../../../Components/Layouts/Main";
import ValidationError from "../../../../Components/ValidationError";
import WhiteContainer from "../../../../Components/WhiteContainer";
import { useNavigate, useParams } from "react-router-dom";
import CodigoAcessoStatus from "../../../../Enums/CodigoAcessoStatus";
import AxiosClient from "../../../../Services/AxiosClient";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import { useEffect, useState } from "react";
import FormButtons from "../../../../Components/Form/FormButtons";
import { ListServiceResult, ServiceResult } from "../../../../Interfaces";
import {
  CodigoAcessoIngressosModel,
  GrupoModel,
  IngressoModel,
} from "../../../../Interfaces/Models";
import VinculoIngressos from "../Components/VinculoIngressos";

interface CodigoAcessoPutBody {
  eventoId: string;
  ingressos: string[] | null;
  limiteDeUso: number;
  status: CodigoAcessoStatus;
}

interface CodigoAcessoViewModel {
  codigo: string;
  status: CodigoAcessoStatus;
}

export default function EditarCodigo() {
  const navigate = useNavigate();

  const { eventoId, codigoId } = useParams();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/codigo-promocional`,
      name: "Código promocional",
    },
    {
      link: `/eventos/${eventoId}/codigo-promocional/editar-codigo/${codigoId}`,
      name: "editar código",
    },
  ];

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CodigoAcessoViewModel>({
    defaultValues: {
      codigo: "",
      status: CodigoAcessoStatus.Ativo,
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [ingressos, setIngressos] = useState<IngressoModel[]>([]);
  const [ingressosSelecionados, setIngressosSelecionados] = useState<string[]>(
    [],
  );
  const [vincularIngresso, setVincularIngresso] = useState<boolean>(false);

  const editarCodigo = async (data: CodigoAcessoViewModel): Promise<void> => {
    const args: CodigoAcessoPutBody = {
      eventoId: eventoId!,
      ingressos: vincularIngresso ? ingressosSelecionados : null,
      limiteDeUso: 0,
      status: data.status,
    };

    toast.promise(
      AxiosClient.put(`/codigos/${codigoId}`, args).then(() =>
        navigate(`/eventos/${eventoId}/codigo-promocional`),
      ),
      {
        loading: "Editando código...",
        success: "Código alterado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      },
    );
  };

  const getCodigo = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<CodigoAcessoIngressosModel>>(
      `/codigos/${codigoId}`,
    )
      .then(({ data: { data } }) => {
        setValue("codigo", data?.codigo ?? "");
        setValue("status", data?.status ?? CodigoAcessoStatus.Ativo);
      })
      .catch((error) => {
        navigate(`/eventos/${eventoId}/codigo-promocional`);
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const getCodigoIngressos = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<IngressoModel[]>>(
      `/codigos/${codigoId}/ingressos`,
    )
      .then(({ data: { data } }) => {
        const selecionados: string[] = data?.map((i) => i.id) ?? [];

        if (selecionados.length > 0) {
          setVincularIngresso(true);
        }

        setIngressosSelecionados(selecionados);
      })
      .catch((error) => {
        navigate(`/eventos/${eventoId}/codigo-promocional`);
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const getIngressos = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<GrupoModel>>(
      `/eventos/${eventoId}/grupos?pagina=1&paginaTamanho=100`,
    )
      .then(({ data: { data } }) => {
        const ingressos: IngressoModel[] =
          data?.flatMap((grupo) => grupo.ingressos) ?? [];

        setIngressos(ingressos);
      })
      .catch((error) => {
        navigate(`/eventos/${eventoId}/codigo-promocional`);
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const toggleVincularIngresso = (): void => {
    setVincularIngresso(!vincularIngresso);
  };

  useEffect(() => {
    getCodigo();
    getIngressos();
    getCodigoIngressos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Editar código</p>
        </div>
      </WhiteContainer>

      <form onSubmit={handleSubmit(editarCodigo)}>
        <WhiteContainer containerPadding={4} rowMarginBottom={4}>
          <div className="row g-3">
            <div className="col-xl-6">
              <p className="text-500-dark-18 mb-2">Crie um nome do código</p>
              <Input
                autoFocus
                name="codigo"
                control={control}
                errors={errors}
                validation={{
                  required: "Informe o nome do código",
                }}
                className="disabled"
                placeholder="Nome do código"
                disabled
              />
              {errors.codigo && ValidationError(errors.codigo)}
            </div>

            <div className="col-xl-8">
              <div className="card-cinza-claro p-3">
                <div className="form-check form-switch d-flex align-items-center ps-5 mb-3">
                  <input
                    className="form-check-input mb-1 cursor-pointer"
                    style={{ width: "3.3em", height: "1.7em" }}
                    type="checkbox"
                    role="switch"
                    id="vincular_ingresso"
                    checked={vincularIngresso}
                    onChange={toggleVincularIngresso}
                  />
                  <label
                    className="form-check-label px-2 text-500-black-16 cursor-pointer"
                    htmlFor="vincular_ingresso"
                  >
                    Vincular ingresso
                  </label>
                </div>
                <p className="text-400-darkest-14 m-0">
                  Vincule os ingressos a este código assim que ele for criado.
                  Eles não estarão mais visíveis ao público sem o uso do código.
                </p>
              </div>
            </div>

            <VinculoIngressos
              loading={loading}
              vincularIngresso={vincularIngresso}
              ingressos={ingressos}
              ingressosSelecionados={ingressosSelecionados}
              setIngressosSelecionados={setIngressosSelecionados}
            />
          </div>
        </WhiteContainer>

        <FormButtons
          submitText="Editar"
          cancelAction={() =>
            navigate(`/eventos/${eventoId}/codigo-promocional`)
          }
        />
      </form>
    </MainLayout>
  );
}
