import { useParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import MainLayout from "../../../Components/Layouts/Main";
import WhiteContainer from "../../../Components/WhiteContainer";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AxiosClient from "../../../Services/AxiosClient";
import moment from "moment";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import FiltroParticipantes from "./Components/FiltroParticipantes";
import { ListServiceResult } from "../../../Interfaces";
import { ParticipanteModel } from "../../../Interfaces/Models";
import DarkGrayCard from "../../../Components/DarkGrayCard";
import ListagemParticipantes from "./Components/ListagemParticipantes";
import SkeletonListagem from "../../../Components/SkeletonListagem";
import Button from "../../../Components/Button";
import EmptyModal from "../../../Components/Modals/EmptyModal";
import { EditModalIcon } from "../../../Components/Icons";

const filtroRadioButtonsTipoBusca = [
  { label: "Nome", value: "nome" },
  { label: "E-mail", value: "email" },
  { label: "Documento", value: "documento" },
];

interface ParticipantesEmailPostArgs {
  assunto: string;
  mensagem: string;
}

export default function Participantes() {
  const { eventoId } = useParams();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/participantes`,
      name: "Participantes",
    },
  ];

  const [loading, setLoading] = useState<boolean>(false);
  const [exportingCsv, setExportingCsv] = useState<boolean>(false);
  const [possuiMaisParticipantes, setPossuiMaisParticipantes] =
    useState<boolean>(false);
  const [pagina, setPagina] = useState<number>(1);
  const [participantes, setParticipantes] = useState<ParticipanteModel[]>([]);

  const [filtros, setFiltros] = useState<{
    filtroString: string;
    tipoBusca: string;
  }>({
    filtroString: "",
    tipoBusca: filtroRadioButtonsTipoBusca[0].value,
  });
  const [filtrosAplicados, setFiltrosAplicados] = useState<string[]>([]);

  const [emailPostArgs, setEmailPostArgs] =
    useState<ParticipantesEmailPostArgs>({
      assunto: "",
      mensagem: "",
    });

  const handleFiltro = () => {
    setFiltrosAplicados([]);

    if (filtros.filtroString) {
      setFiltrosAplicados((prev) => [...prev, filtros.filtroString]);
    }

    setPagina(1);
    setParticipantes([]);
  };

  const removerFiltro = (filtro: string): void => {
    if (filtro === filtros.filtroString) {
      setFiltros({
        ...filtros,
        filtroString: "",
        tipoBusca: filtroRadioButtonsTipoBusca[0].value,
      });
    }

    setFiltrosAplicados((prev) => prev.filter((f) => f !== filtro));

    setParticipantes([]);
  };

  const getRequestPathWIthQueryParams = (): string => {
    let url: string = `/eventos/${eventoId}/participantes`;
    url += `?pagina=${pagina}`;
    url += "&paginaTamanho=10";

    if (filtros.filtroString) {
      url += `&${filtros.tipoBusca}=${filtros.filtroString}`;
    }

    return url;
  };

  const getParticipantes = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<ParticipanteModel>>(
      getRequestPathWIthQueryParams()
    )
      .then(({ data }) => {
        setParticipantes((prev) => [...prev, ...(data?.data ?? [])]);
        setPossuiMaisParticipantes(data.pages! > data.page!);
      })
      .catch((error) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  const exportarCsv = async (): Promise<void> => {
    setExportingCsv(true);

    toast.promise(
      AxiosClient.get(`/eventos/${eventoId}/participantes/csv`, {
        responseType: "blob",
      })
        .then(({ data }) => {
          const blob = new Blob([data], {
            type: "text/csv",
          });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `participantes-${moment().format("DD-MM-YYYY")}.csv`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .finally(() => setExportingCsv(false)),
      {
        loading: "Exportando CSV...",
        success: "CSV exportado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const [auxParticipanteModel, setAuxParticipanteModel] =
    useState<ParticipanteModel>({} as ParticipanteModel);

  const [modalEnviarMensagem, setModalEnviarMensagem] = useState<string | null>(
    null
  );

  const abrirModalEnviarMensagem = (participante: ParticipanteModel): void => {
    setAuxParticipanteModel(participante);
    setModalEnviarMensagem(participante.id);
  };

  const resetModalEnviarMensagem = (): void => {
    setModalEnviarMensagem(null);
    setAuxParticipanteModel({} as ParticipanteModel);
    setEmailPostArgs({
      assunto: "",
      mensagem: "",
    });
  };

  const enviarMensagem = async (): Promise<void> => {
    if (!emailPostArgs.assunto || !emailPostArgs.mensagem) {
      toast.error("Preencha todos os campos para enviar o e-mail!");
      return;
    }

    setModalEnviarMensagem(null);

    toast.promise(
      AxiosClient.post(
        `/eventos/${eventoId}/participantes/${auxParticipanteModel.id}/mensagem`,
        emailPostArgs
      ).then(() => {
        setEmailPostArgs({
          assunto: "",
          mensagem: "",
        });
      }),
      {
        loading: "Enviando mensagem...",
        success: "Email enviado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  // * Infinite scroll * //
  useEffect(() => {
    function handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (
        !loading &&
        possuiMaisParticipantes &&
        scrollTop + clientHeight >= scrollHeight - 500
      ) {
        setLoading(true);
        setPagina(pagina + 1);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, pagina, possuiMaisParticipantes]);

  useEffect(() => {
    getParticipantes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagina, filtrosAplicados]);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Participantes</p>

          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <FiltroParticipantes
              handleFiltro={handleFiltro}
              filtroRadioButtonsTipoBusca={filtroRadioButtonsTipoBusca}
              filtros={filtros}
              setFiltros={setFiltros}
            />

            <button
              type="button"
              onClick={exportarCsv}
              className={`bc-btn bc-btn-primary px-3 py-2 w-00 h-00 ${
                (!loading && participantes.length === 0) || loading
                  ? "disabled"
                  : ""
              }`}
              disabled={
                (!loading && participantes.length === 0) ||
                loading ||
                exportingCsv
              }
            >
              Exportar CSV
            </button>
          </div>
        </div>
      </WhiteContainer>

      <WhiteContainer
        rowMarginBottom={4}
        containerPadding={4}
        containerWidth={12}
      >
        {filtrosAplicados.length > 0 && (
          <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
            {filtrosAplicados.map((filtro, index) => (
              <div className="me-3 mb-2" key={index}>
                <div
                  className="d-flex flex-wrap justify-content-center align-items-center px-4"
                  style={{ borderRadius: 50, background: "#F9F8F8" }}
                >
                  <p className="text-400-black-16 m-0 me-2 my-2">Filtro:</p>
                  <p className="text-400-black-16 m-0 me-2 my-2 text-break">
                    {filtro}
                  </p>
                  <button
                    className="btn-close my-2"
                    onClick={() => removerFiltro(filtro)}
                  ></button>
                </div>
              </div>
            ))}
          </div>
        )}

        {participantes.length > 0 && (
          <ListagemParticipantes
            participantes={participantes}
            abrirModalEnviarMensagem={abrirModalEnviarMensagem}
          />
        )}

        {loading && <SkeletonListagem />}

        {!loading && participantes.length === 0 && (
          <DarkGrayCard message="Você ainda não tem participantes por aqui!" />
        )}

        {modalEnviarMensagem && (
          <EmptyModal
            close={resetModalEnviarMensagem}
            isOpen={modalEnviarMensagem !== null}
          >
            <div className="row d-flex justify-content-center align-itens-center text-center">
              <EditModalIcon />

              <p className="title-h1 text-black m-0">
                Envie uma mensagem ao participante
              </p>
              <div className="row g-3 mt-3 mb-4">
                <div className="col-lg-12">
                  <p className="text-500-black-16 m-0 text-start">Assunto</p>

                  <input
                    autoFocus
                    type="text"
                    placeholder="Assunto"
                    className="form-control backstage-input"
                    style={{ height: 40 }}
                    value={emailPostArgs.assunto}
                    onChange={({ target: { value } }) =>
                      setEmailPostArgs({ ...emailPostArgs, assunto: value })
                    }
                  />
                </div>

                <div className="col-lg-12">
                  <p className="text-500-black-16 m-0 text-start">Mensagem</p>

                  <textarea
                    className="form-control backstage-input"
                    placeholder="Mensagem"
                    value={emailPostArgs.mensagem}
                    onChange={({ target: { value } }) =>
                      setEmailPostArgs({ ...emailPostArgs, mensagem: value })
                    }
                    style={{ height: 100, resize: "none" }}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-xxl-12">
                  <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
                    <Button
                      variant="light"
                      text="Cancelar"
                      onClick={resetModalEnviarMensagem}
                      className="px-3 py-2 mb-3 mb-md-0 me-md-3"
                      width="100%"
                    />

                    <Button
                      text="Enviar e-mail"
                      className="px-3 py-2"
                      width="100%"
                      onClick={enviarMensagem}
                    />
                  </div>
                </div>
              </div>
            </div>
          </EmptyModal>
        )}
      </WhiteContainer>
    </MainLayout>
  );
}
