interface Props {
  totalDisponivel: number;
}

export default function CardDisponivel({ totalDisponivel }: Props) {
  return (
    <div className="col-12 card-cinza-escuro p-3">
      <div className="d-flex flex-wrap align-items-center justify-content-between h-100">
        <div className="d-flex flex-column justify-content-center">
          <p className="text-500-black-18 m-0">Disponível</p>
          <p className="text-number-card-home m-0 text-break text-black fs-2">
            {totalDisponivel.toString().length < 2
              ? "0" + totalDisponivel
              : totalDisponivel}
          </p>
        </div>
        <SvgIconBarChart />
      </div>
    </div>
  );
}

const SvgIconBarChart = (): JSX.Element => (
  <svg
    width="88"
    height="77"
    viewBox="0 0 88 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84 0C81.7909 0 80 1.79086 80 4V73C80 75.2091 81.7909 77 84 77C86.2091 77 88 75.2091 88 73V4C88 1.79086 86.2091 0 84 0ZM20 28.875C17.7909 28.875 16 30.6659 16 32.875V73C16 75.2091 17.7909 77 20 77C22.2091 77 24 75.2091 24 73V32.875C24 30.6659 22.2091 28.875 20 28.875ZM4 57.75C1.79086 57.75 0 59.5409 0 61.75V73C0 75.2091 1.79086 77 4 77C6.20914 77 8 75.2091 8 73V61.75C8 59.5409 6.20914 57.75 4 57.75ZM32 42.5C32 40.2909 33.7909 38.5 36 38.5C38.2091 38.5 40 40.2909 40 42.5V73C40 75.2091 38.2091 77 36 77C33.7909 77 32 75.2091 32 73V42.5ZM52 45.7188C49.7909 45.7188 48 47.5096 48 49.7188V73C48 75.2091 49.7909 77 52 77C54.2091 77 56 75.2091 56 73V49.7188C56 47.5096 54.2091 45.7188 52 45.7188ZM64 38.8906C64 36.6815 65.7909 34.8906 68 34.8906C70.2091 34.8906 72 36.6815 72 38.8906V73C72 75.2091 70.2091 77 68 77C65.7909 77 64 75.2091 64 73V38.8906Z"
      fill="black"
      fillOpacity="0.2"
    />
    <rect y="61" width="8" height="16" rx="4" fill="#303F9F" />
    <rect x="16" y="37" width="8" height="40" rx="4" fill="#303F9F" />
    <rect x="32" y="45" width="8" height="32" rx="4" fill="#303F9F" />
    <rect x="48" y="51" width="8" height="26" rx="4" fill="#303F9F" />
    <rect x="64" y="42" width="8" height="35" rx="4" fill="#303F9F" />
    <rect x="80" y="13" width="8" height="64" rx="4" fill="#303F9F" />
  </svg>
);
