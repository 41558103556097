import { WhiteLabel } from "../Utils";

type LoadingProps = {
  container?: "0" | "30vh" | "50vh" | "75vh" | "100vh" | false;
  loadingSize?: string;
};

export default function Loading(props: LoadingProps) {
  const { container = false, loadingSize = "2rem" } = props;
  return (
    <>
      {container ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: container }}
        >
          <div
            className="spinner-border"
            style={{
              width: loadingSize,
              height: loadingSize,
              color: WhiteLabel().colorPrimary,
            }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div
          className="spinner-border"
          style={{
            width: loadingSize,
            height: loadingSize,
            color: WhiteLabel().colorPrimary,
          }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </>
  );
}
