import TipoPagamento from "../Enums/TipoPagamento";

export default function TipoPagamentoToString(tipo: TipoPagamento) {
  switch (tipo) {
    case TipoPagamento.CartaoCredito:
      return "Cartão de Crédito";
    case TipoPagamento.Pix:
      return "PIX";
    case TipoPagamento.ApplePay:
      return "Apple Pay";
    case TipoPagamento.Cortesia:
      return "Cortesia";
    default:
      return "Tipo de pagamento não encontrado";
  }
}
