import { useState } from "react";
import Button from "../../../../Components/Button";
import EmptyModal from "../../../../Components/Modals/EmptyModal";
import CodigoAcessoModel from "../../../../Interfaces/Models/CodigoAcessoModel";
import clsx from "clsx";
import { CloseDarkIcon } from "../../../../Components/Icons";

interface Props {
  codigos: CodigoAcessoModel[];
  codigosSelecionados: CodigoAcessoModel[];
  setCodigosSelecionados: React.Dispatch<
    React.SetStateAction<CodigoAcessoModel[]>
  >;
}

export default function VincularCodigoAcesso({
  codigos,
  codigosSelecionados,
  setCodigosSelecionados,
}: Props) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [codigosTmp, setCodigosTmp] =
    useState<CodigoAcessoModel[]>(codigosSelecionados);

  const selecionarCodigo = (codigo: CodigoAcessoModel) => {
    const codigos = [...codigosTmp];
    const codigoExistente = codigos.find((c) => c.id === codigo.id);

    if (codigoExistente) {
      codigos.splice(codigos.indexOf(codigoExistente), 1);
    } else {
      codigos.push(codigo);
    }

    setCodigosTmp(codigos);
  };

  const cancelarSelecaoCodigo = (): void => {
    setCodigosTmp(codigosSelecionados);
    setModalOpen(false);
  };

  const confirmarSelecaoCodigo = (): void => {
    setCodigosSelecionados(codigosTmp);
    setModalOpen(false);
  };

  const toggleSelecionarTodos = () => {
    if (codigosTmp.length === codigos.length) {
      setCodigosTmp([]);
    } else {
      setCodigosTmp(codigos);
    }
  };

  return (
    <div className="col-xl-8">
      <div className="card-cinza-claro p-3">
        <p className="text-500-black-18 mb-2">Vincular código</p>
        <p className="text-400-darkest-14 mb-2">
          {codigos.length > 0
            ? "Selecione os códigos para aparecer na tela de compra para este ingresso"
            : "Este evento ainda não possui códigos."}
        </p>

        {codigosSelecionados.length > 0 && (
          <div className="row g-2">
            {codigosSelecionados.map((c) => (
              <div className="col-lg-6 col-xl-4" key={c.id}>
                <div className="d-flex align-items-center bg-white rounded-pill py-2 px-3">
                  <div
                    onClick={() => selecionarCodigo(c)}
                    className="pe-2 cursor-pointer"
                  >
                    <CloseDarkIcon />
                  </div>
                  <p className="text-500-black-16 text-break m-0">{c.codigo}</p>
                </div>
              </div>
            ))}
          </div>
        )}

        {codigos.length > 0 && (
          <Button
            className="mt-3"
            text="Vincular"
            variant={codigosSelecionados.length > 0 ? "primary" : "light"}
            onClick={() => setModalOpen(true)}
          />
        )}

        <EmptyModal
          isOpen={modalOpen}
          close={cancelarSelecaoCodigo}
          title="Selecione os códigos"
        >
          <div className="form-check form-switch d-flex align-items-center ps-5 mb-2">
            <input
              className="form-check-input mb-1"
              style={{ width: "3.3em", height: "1.7em" }}
              type="checkbox"
              role="switch"
              id="selecionar_todos"
              checked={codigosTmp.length === codigos.length}
              onChange={toggleSelecionarTodos}
            />
            <label
              className="form-check-label ps-2 cursor-pointer"
              htmlFor="selecionar_todos"
            >
              Selecionar todos
            </label>
          </div>

          <div
            className="pe-2 mb-4"
            style={{ maxHeight: 340, overflowY: "auto" }}
          >
            {codigos.map((c) => (
              <div
                key={c.id}
                className={clsx(
                  "card-cinza-claro cursor-pointer p-1 mb-1",
                  codigosTmp.some((codigo) => codigo.id === c.id) &&
                    "border border-2",
                )}
                onClick={() => selecionarCodigo(c)}
              >
                <div className="d-flex align-items-center p-2">
                  <input
                    type="checkbox"
                    className="cursor-pointer form-check-input mt-0"
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 10,
                    }}
                    checked={codigosTmp.some((codigo) => codigo.id === c.id)}
                    onChange={() => selecionarCodigo(c)}
                  />
                  <p className="text-400-dark-16 text-break m-0 ">{c.codigo}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center mb-3">
            <button
              className="bc-btn bc-btn-light px-3 py-2 h-100 mb-3 mb-sm-0"
              style={{ width: 175 }}
              onClick={cancelarSelecaoCodigo}
            >
              Cancelar
            </button>

            <button
              className="bc-btn bc-btn-primary px-3 py-2 h-100"
              style={{ width: 175 }}
              onClick={confirmarSelecaoCodigo}
            >
              Confirmar
            </button>
          </div>
        </EmptyModal>
      </div>
    </div>
  );
}
