import clsx from "clsx";
import Button from "../../../../Components/Button";
import { CloseDarkIcon } from "../../../../Components/Icons";
import EmptyModal from "../../../../Components/Modals/EmptyModal";
import IngressoModel from "../../../../Interfaces/Models/IngressoModel";
import { useState } from "react";

interface Props {
  ingressos: IngressoModel[];
  ingressosSelecionados: IngressoModel[];
  setIngressosSelecionados: React.Dispatch<
    React.SetStateAction<IngressoModel[]>
  >;
}

export default function ViradaAutomaticaIngressos({
  ingressos,
  ingressosSelecionados,
  setIngressosSelecionados,
}: Props) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [ingressosTmp, setIngressosTmp] = useState<IngressoModel[]>(
    ingressosSelecionados,
  );

  const removerIngressoSelecionado = (ingresso: IngressoModel): void => {
    setIngressosSelecionados((prev) =>
      prev.filter((i) => i.id !== ingresso.id),
    );

    setIngressosTmp((prev) => prev.filter((i) => i.id !== ingresso.id));
  };

  const selecionarIngresso = (ingresso: IngressoModel): void => {
    const ingressos = [...ingressosTmp];
    const index = ingressos.indexOf(ingresso);

    if (index > -1) {
      ingressos.splice(index, 1);
    } else {
      ingressos.push(ingresso);
    }

    setIngressosTmp(ingressos);
  };

  const cancelarSelecaoIngresso = (): void => {
    setIngressosTmp(ingressosSelecionados);
    setModalOpen(false);
  };

  const confirmarSelecaoIngresso = (): void => {
    setIngressosSelecionados(ingressosTmp);
    setModalOpen(false);
  };

  return (
    <div className="col-xl-8">
      <div className="card-cinza-claro p-3">
        <p className="text-500-black-18 mb-2">Virada automática de ingressos</p>
        <p className="text-400-darkest-14 mb-2">
          Quando qualquer um dos ingressos selecionados abaixo acabar, este
          ingresso estará disponível para venda.
        </p>

        {ingressosSelecionados.length > 0 && (
          <div className="row g-2">
            {ingressosSelecionados.map((i) => (
              <div className="col-lg-6" key={i.id}>
                <div className="d-flex align-items-center bg-white rounded-pill py-2 px-3">
                  <div
                    onClick={() => removerIngressoSelecionado(i)}
                    className="pe-2 cursor-pointer"
                  >
                    <CloseDarkIcon />
                  </div>
                  <p className="text-500-black-16 m-0">{i.nome}</p>
                </div>
              </div>
            ))}
          </div>
        )}

        <Button
          className="mt-3"
          text="Incluir ingressos"
          variant={ingressosSelecionados.length > 0 ? "primary" : "light"}
          onClick={() => setModalOpen(true)}
        />

        <EmptyModal
          isOpen={modalOpen}
          close={cancelarSelecaoIngresso}
          title="Selecione os ingressos"
        >
          <div
            className="pe-2 mb-4"
            style={{ maxHeight: 340, overflowY: "auto" }}
          >
            {ingressos.map((i) => (
              <div
                key={i.id}
                className={clsx(
                  "card-cinza-claro cursor-pointer p-1 mb-1",
                  ingressosTmp.includes(i) && "border border-2",
                )}
                onClick={() => selecionarIngresso(i)}
              >
                <div className="d-flex align-items-center p-2">
                  <input
                    type="checkbox"
                    className="cursor-pointer form-check-input mt-0"
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 10,
                    }}
                    checked={ingressosTmp.includes(i)}
                    onChange={() => selecionarIngresso(i)}
                  />
                  <p className="text-400-dark-16 text-break m-0 ">{i.nome}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center mb-3">
            <button
              className="bc-btn bc-btn-light px-3 py-2 h-100 mb-3 mb-sm-0"
              style={{ width: 175 }}
              onClick={cancelarSelecaoIngresso}
            >
              Cancelar
            </button>

            <button
              className="bc-btn bc-btn-primary px-3 py-2 h-100"
              style={{ width: 175 }}
              onClick={confirmarSelecaoIngresso}
            >
              Confirmar
            </button>
          </div>
        </EmptyModal>
      </div>
    </div>
  );
}
