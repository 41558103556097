import moment from "moment";
import { ListagemPedidosModel } from "../../../../Interfaces/Models";
import {
  AplicarMascaraDocumento,
  FormatarCentavosParaReais,
  PagamentoStatusToString,
} from "../../../../Utils";
import BotoesListagemPedidos from "./BotoesListagemPedidos";
import TipoPagamento from "../../../../Enums/TipoPagamento";
import { useState } from "react";
import DetalhesPedido from "./DetalhesPedido";

interface Props {
  eventoId: string;
  pedidos: ListagemPedidosModel[];
  abrirModalCancelarPedido: (pedido: ListagemPedidosModel) => void;
}

export default function ListagemPedidos({
  eventoId,
  pedidos,
  abrirModalCancelarPedido,
}: Props) {
  const [exibindoPedido, setExibindoPedido] = useState<string | null>(null);

  const toggleExibicao = (pedidoId: string): void => {
    if (exibindoPedido === pedidoId) {
      setExibindoPedido(null);
    } else {
      setExibindoPedido(pedidoId);
    }
  };

  return (
    <div className="row">
      <div className="d-none d-lg-block col-lg-12 mb-3">
        <div className="row">
          <div className="col-lg-1">
            <p className="text-500-black-16 m-0">Data</p>
          </div>
          <div className="col-lg-2">
            <p className="text-500-black-16 m-0">Nome</p>
          </div>
          <div className="col-lg-1">
            <p className="text-500-black-16 m-0">Documento</p>
          </div>
          <div className="col-lg-2">
            <p className="text-500-black-16 m-0">E-mail</p>
          </div>
          <div className="col-lg-1">
            <p className="text-500-black-16 m-0">Valor</p>
          </div>
          <div className="col-lg-2">
            <p className="text-500-black-16 m-0">Códigos Utilizados</p>
          </div>
          <div className="col-lg-1">
            <p className="text-500-black-16 m-0">Status</p>
          </div>
        </div>
      </div>

      {pedidos.map((pedido) => (
        <div className="col-lg-12" key={pedido.id}>
          <div className="card-cinza-claro p-3 mb-3">
            <div className="row">
              <div className="align-self-center col-lg-1">
                <p className="d-block d-lg-none text-500-black-16 m-0">Data</p>
                <p className="text-500-darkest-14 text-break m-0">
                  {moment(pedido.criadoEm).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="align-self-center col-6 col-lg-2">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Nome
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {pedido.compradorNome}
                </p>
              </div>

              <div className="align-self-center col-6 col-lg-1">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Documento
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {AplicarMascaraDocumento(pedido.compradorDocumento)}
                </p>
              </div>
              <div className="align-self-center col-lg-2">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Email
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {pedido.compradorEmail}
                </p>
              </div>
              <div className="align-self-center col-6 col-lg-1">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Valor
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {pedido.formaPagamento === TipoPagamento.Cortesia
                    ? "Cortesia"
                    : FormatarCentavosParaReais(pedido.totalCentavos)}
                </p>
              </div>
              <div className="align-self-center col-6 col-lg-2">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Codigos Utilizados
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {typeof pedido.codigosAcessoUtilizados === "string"
                    ? pedido.codigosAcessoUtilizados
                    : pedido.codigosAcessoUtilizados?.join(", ") ?? "-"}
                </p>
              </div>
              <div className="align-self-center col-6 col-lg-1">
                <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                  Status
                </p>
                <p className="text-500-darkest-14 text-break m-0">
                  {PagamentoStatusToString(pedido.statusPedido)}
                </p>
              </div>

              <div className="align-self-center col-lg-2">
                <BotoesListagemPedidos
                  pedido={pedido}
                  exibindoPedido={exibindoPedido}
                  toggleExibicao={toggleExibicao}
                  abrirModalCancelarPedido={abrirModalCancelarPedido}
                />
              </div>
            </div>
          </div>

          {exibindoPedido === pedido.id && (
            <DetalhesPedido eventoId={eventoId} pedidoId={pedido.id} />
          )}
        </div>
      ))}

      {/* 
      <div className="d-flex justify-content-center mt-3">
        <button
          type="button"
          onClick={() => setPagina((prev) => prev + 1)}
          className={clsx(
            "bc-btn bc-btn-primary px-3 py-2 mx-auto",
            !possuiMaisPedidos && "disabled"
          )}
          disabled={!possuiMaisPedidos}
        >
          Carregar mais
        </button>
      </div>
      */}
    </div>
  );
}
