import { GetIsRefreshing, SetIsRefreshing } from "./isRefreshing";
import axios from "axios";
import { AutenticacaoModel } from "../Interfaces/Models";
import { ServiceResult } from "../Interfaces";

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "X-EventTicket-Tenant": process.env.REACT_APP_TENANT || "TICKETSAPP",
    "Access-Control-Allow-Origin": "*",
  },
};

const AxiosClient = axios.create(defaultOptions);

let refreshFailedCount = 0;
const MAX_REFRESH_FAILED_COUNT = 3;

const refreshToken = async (token: string): Promise<AutenticacaoModel> => {
  return AxiosClient.postForm<ServiceResult<AutenticacaoModel>>("/auth/token", {
    grant_type: "refresh_token",
    lembrarSenha: true,
    refresh_token: token,
  })
    .then(({ data: { data } }) => {
      localStorage.setItem("auth", JSON.stringify(data));
      refreshFailedCount = 0;
      return data as AutenticacaoModel;
    })
    .catch((error) => {
      refreshFailedCount++;
      throw error;
    });
};

const handleRefreshError = (error: any) => {
  localStorage.clear();
  return Promise.reject(error);
};

const shouldRefreshToken = (error: any): boolean => {
  return (
    error.response &&
    error.response.status === 401 &&
    refreshFailedCount < MAX_REFRESH_FAILED_COUNT
  );
};

const refreshAuthToken = async (error: any) => {
  SetIsRefreshing(true);

  try {
    const auth: AutenticacaoModel = JSON.parse(
      localStorage.getItem("auth") || "{}",
    );
    if (!auth.refreshToken) {
      return handleRefreshError(error);
    }

    const newToken = await refreshToken(auth.refreshToken);
    error.config.headers.Authorization = `Bearer ${newToken.accessToken}`;
    return AxiosClient.request(error.config);
  } catch (refreshError) {
    return handleRefreshError(refreshError);
  } finally {
    SetIsRefreshing(false);
  }
};

AxiosClient.interceptors.request.use((config) => {
  const auth = JSON.parse(
    localStorage.getItem("auth") ?? sessionStorage.getItem("auth") ?? "{}",
  );

  if (auth.accessToken) {
    config.headers.Authorization = `Bearer ${auth.accessToken}`;
  }

  return config;
});

AxiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (shouldRefreshToken(error)) {
      while (GetIsRefreshing()) {
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      return refreshAuthToken(error);
    }

    return Promise.reject(error);
  },
);

export default AxiosClient;
