import { useParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import MainLayout from "../../../Components/Layouts/Main";
import WhiteContainer from "../../../Components/WhiteContainer";
import CadastrarCoprodutor from "./Components/CadastrarCoprodutor";
import ListagemCoprodutores from "./Components/ListagemCoprodutores";
import { useState } from "react";
import { AddIcon, ChevronUpIcon } from "../../../Components/Icons";

export default function Coprodutores() {
  const { eventoId } = useParams();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/coprodutores`,
      name: "Coprodutores",
    },
  ];

  const [cadastrandoCoprodutor, setCadastrandoCoprodutor] =
    useState<boolean>(false);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4}>
        <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center">
          <p className="title-h1 text-black mb-2">Coprodutores</p>
          <button
            className="bc-btn bc-btn-primary py-2 px-3 d-flex justify-content-center align-items-center"
            onClick={() => setCadastrandoCoprodutor((prev) => !prev)}
          >
            <p className="text-500-white-16 pe-2 m-0">Cadastrar</p>
            {cadastrandoCoprodutor ? <ChevronUpIcon /> : <AddIcon />}
          </button>
        </div>
      </WhiteContainer>

      {cadastrandoCoprodutor && <CadastrarCoprodutor eventoId={eventoId!} />}

      <ListagemCoprodutores eventoId={eventoId!} />
    </MainLayout>
  );
}
