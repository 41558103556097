import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import MainLayout from "../../../Components/Layouts/Main";
import WhiteContainer from "../../../Components/WhiteContainer";
import { useEffect, useState } from "react";
import { CodigoAcessoModel } from "../../../Interfaces/Models";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import toast from "react-hot-toast";
import { ServiceResult } from "../../../Interfaces";
import AxiosClient from "../../../Services/AxiosClient";
import DarkGrayCard from "../../../Components/DarkGrayCard";
import Loading from "../../../Components/Loading";
import FiltroCodigos from "./Components/FiltroCodigos";
import CodigosPromocionais from "./Components/CodigosPromocionais";
import PromptModal from "../../../Components/Modals/PromptModal";
import { DangerModalIcon } from "../../../Components/Icons";

export default function CodigoPromocional() {
  const { eventoId } = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchString = searchParams.get("searchString") || "";

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/codigo-promocional`,
      name: "Código promocional",
    },
  ];

  const filtroRadioButtons = [
    { label: "Código ativo", value: "ativo" },
    { label: "Código desativado", value: "inativo" },
  ];

  const [loading, setLoading] = useState<boolean>(false);
  const [codigos, setCodigos] = useState<CodigoAcessoModel[]>([]);

  const [filtros, setFiltros] = useState<{
    filtroString: string;
    filtroStatus: string;
  }>({
    filtroString: "",
    filtroStatus: "",
  });

  const [filtrosAplicados, setFiltrosAplicados] = useState<string[]>([]);

  const possuiCodigosOuFiltrosAplicados =
    !loading &&
    (codigos.length > 0 ||
      (codigos.length === 0 && filtrosAplicados.length > 0));

  const naoPossuiCodigos = !loading && codigos.length === 0;

  const handleFiltro = () => {
    setFiltrosAplicados([]);

    if (filtros.filtroString) {
      setFiltrosAplicados((prev) => [...prev, filtros.filtroString]);
    }

    if (filtros.filtroStatus) {
      setFiltrosAplicados((prev) => [...prev, filtros.filtroStatus]);
    }

    setCodigos([]);
  };

  const removerFiltro = (filtro: string): void => {
    if (filtro === filtros.filtroString) {
      setFiltros({
        ...filtros,
        filtroString: "",
      });
    }

    if (filtro === filtros.filtroStatus) {
      setFiltros({
        ...filtros,
        filtroStatus: "",
      });
    }

    setFiltrosAplicados((prev) => prev.filter((f) => f !== filtro));

    setCodigos([]);
  };

  const getRequestPathWIthQueryParams = () => {
    let url: string = "/codigos";
    url += `?pagina=${1}`;
    url += "&paginaTamanho=100";
    url += "&apenasComIngressosDisponiveis=false";
    url += "&apenasVisiveis=false";
    url += `&evento=${eventoId}`;

    // verifica se tem filtro por string / search string (header)
    if (searchString) {
      url += `&searchString=${searchString}`;
    } else if (filtros.filtroString) {
      url += `&searchString=${filtros.filtroString}`;
    }

    // verifica se tem filtro por status
    if (filtros.filtroStatus) {
      if (filtros.filtroStatus === "ativo") {
        url += `&status=Ativo`;
      }

      if (filtros.filtroStatus === "inativo") {
        url += `&status=Inativo`;
      }
    }

    return url;
  };

  const getCodigos = async () => {
    setLoading(true);

    AxiosClient.get<ServiceResult<CodigoAcessoModel[]>>(
      getRequestPathWIthQueryParams(),
    )
      .then(({ data: { data } }) => {
        // sortby data criacao
        data!.sort((a, b) => {
          return (
            new Date(b.criadoEm).getTime() - new Date(a.criadoEm).getTime()
          );
        });

        setCodigos(data as CodigoAcessoModel[]);
      })
      .catch((error: any) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  const [auxCodigoModel, setAuxCodigoModel] = useState<CodigoAcessoModel>(
    {} as CodigoAcessoModel,
  );
  const [modalExcluirCodigo, setModalExcluirCodigo] = useState<string | null>(
    null,
  );

  const abrirModalExcluirCodigo = (codigo: CodigoAcessoModel) => {
    setModalExcluirCodigo(codigo.id);
    setAuxCodigoModel(codigo);
  };

  const excluirCodigo = async (): Promise<void> => {
    setModalExcluirCodigo(null);

    toast.promise(
      AxiosClient.delete(`/codigos/${auxCodigoModel.id}`)
        .then(() => {
          setCodigos(codigos.filter((c) => c.id !== auxCodigoModel.id));
        })
        .finally(() => setAuxCodigoModel({} as CodigoAcessoModel)),
      {
        loading: "Excluindo código...",
        success: "Código excluído com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      },
    );
  };

  useEffect(() => {
    if (searchString) {
      setCodigos([]);
    }

    getCodigos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, filtrosAplicados]);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer
        rowMarginBottom={3}
        containerPadding={4}
        containerWidth={12}
      >
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">
            Código promocional
          </p>

          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <FiltroCodigos
              handleFiltro={handleFiltro}
              filtroRadioButtons={filtroRadioButtons}
              filtros={filtros}
              setFiltros={setFiltros}
            />

            <button
              type="button"
              onClick={() =>
                navigate(
                  `/eventos/${eventoId}/codigo-promocional/cadastrar-codigo`,
                )
              }
              className="bc-btn bc-btn-primary px-3 py-2 w-100 h-100"
            >
              Adicionar código
            </button>
          </div>
        </div>
      </WhiteContainer>

      <WhiteContainer
        rowMarginBottom={4}
        containerPadding={4}
        containerWidth={12}
      >
        {filtrosAplicados.length > 0 && (
          <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
            {filtrosAplicados.map((filtro, index) => (
              <div className="me-3 mb-2" key={index}>
                <div
                  className="d-flex flex-wrap justify-content-center align-items-center px-4"
                  style={{
                    borderRadius: 50,
                    background: "#F9F8F8",
                  }}
                >
                  <p className="text-400-black-16 m-0 me-2 my-2">Filtro:</p>
                  <p className="text-400-black-16 m-0 me-2 my-2 text-break">
                    {filtroRadioButtons.find((btn) => btn.value === filtro)
                      ?.label ?? filtro}
                  </p>
                  <button
                    className="btn-close my-2"
                    onClick={() => removerFiltro(filtro)}
                  ></button>
                </div>
              </div>
            ))}
          </div>
        )}

        {loading && <Loading container="50vh" />}

        {possuiCodigosOuFiltrosAplicados && (
          <CodigosPromocionais
            codigos={codigos}
            eventoId={eventoId!}
            updateStatusCodigo={() => getCodigos()}
            abrirModalExcluirCodigo={abrirModalExcluirCodigo}
          />
        )}

        {naoPossuiCodigos && (
          <DarkGrayCard message="Você ainda não tem códigos promocionais por aqui!" />
        )}

        {/*
          --------------------------------------------------------------------------
                               Modal: Excluir código promocional                    
          --------------------------------------------------------------------------
        */}
        {modalExcluirCodigo && (
          <PromptModal
            isOpen={modalExcluirCodigo !== null}
            close={() => setModalExcluirCodigo(null)}
            icon={<DangerModalIcon />}
            title="Excluir código"
            subtitle={`Tem certeza que deseja excluir o código "${auxCodigoModel.codigo}” ?`}
            cancelText="Cancelar"
            cancelAction={() => setModalExcluirCodigo(null)}
            confirmText="Excluir"
            confirmAction={excluirCodigo}
          />
        )}
      </WhiteContainer>
    </MainLayout>
  );
}
