import { useParams } from "react-router";
import MainLayout from "../../../Components/Layouts/Main";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import WhiteContainer from "../../../Components/WhiteContainer";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AxiosClient from "../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import Loading from "../../../Components/Loading";
import { DangerModalIcon } from "../../../Components/Icons";
import GrupoModel from "../../../Interfaces/Models/GrupoModel";
import ListServiceResult from "../../../Interfaces/ListServiceResult";
import moment from "moment";
import PromptModal from "../../../Components/Modals/PromptModal";
import IngressosDoGrupo from "./Components/IngressosDoGrupo";
import BotoesListagemGrupo from "./Components/BotoesListagemGrupo";
import DarkGrayCard from "../../../Components/DarkGrayCard";

export default function Ingressos() {
  const { eventoId } = useParams();
  const navigate = useNavigate();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/ingressos`,
      name: "Ingressos",
    },
  ];

  const [loading, setLoading] = useState<boolean>(false);
  const [exportingCsv, setExportingCsv] = useState<boolean>(false);
  const [grupos, setGrupos] = useState<GrupoModel[]>([]);
  const [modalExcluirGrupo, setModalExcluirGrupo] = useState<string | null>(
    null,
  );
  const [grupoExcluir, setGrupoExcluir] = useState<GrupoModel>(
    {} as GrupoModel,
  );
  const [exibindoGrupo, setExibindoGrupo] = useState<string | null>(null);

  const toggleExibicao = (grupoId: string): void => {
    if (exibindoGrupo === grupoId) {
      setExibindoGrupo(null);
    } else {
      setExibindoGrupo(grupoId);
    }
  };

  const abrirModalExcluirGrupo = (grupo: GrupoModel): void => {
    setGrupoExcluir(grupo);
    setModalExcluirGrupo(grupo.id);
  };

  const excluirGrupo = async (): Promise<void> => {
    setModalExcluirGrupo(null);
    setLoading(true);

    toast.promise(
      AxiosClient.delete(`/eventos/${eventoId}/grupos/${grupoExcluir.id}`)
        .then(() => {
          const gruposCopy = [...grupos];

          const index = gruposCopy.findIndex((g) => g.id === grupoExcluir.id);
          gruposCopy.splice(index, 1);

          setGrupos(gruposCopy);
        })
        .finally(() => setLoading(false)),
      {
        loading: "Excluindo grupo...",
        success: "Grupo excluído com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      },
    );
  };

  const exportarCsv = async (): Promise<void> => {
    setExportingCsv(true);

    toast.promise(
      AxiosClient.get(`/eventos/${eventoId}/ingressos/csv`, {
        responseType: "blob",
      })
        .then(({ data }) => {
          const blob = new Blob([data], {
            type: "text/csv",
          });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `ingressos-${moment().format("DD-MM-YYYY")}.csv`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .finally(() => setExportingCsv(false)),
      {
        loading: "Exportando CSV...",
        success: "CSV exportado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      },
    );
  };

  const getGrupos = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ListServiceResult<GrupoModel>>(
      `/eventos/${eventoId}/grupos?paginaTamanho=100`,
    )
      .then(({ data: { data } }) => {
        setGrupos(data as GrupoModel[]);
      })
      .catch((error: any) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getGrupos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4} containerWidth={12}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">
            Gerenciar ingressos
          </p>
          <div className="row flex-wrap align-items-center g-3">
            <div className="col-md-6">
              <button
                onClick={exportarCsv}
                className={`bc-btn bc-btn-light px-3 py-2 me-3 w-100 h-100 ${
                  (grupos.length === 0 || loading) && "disabled"
                }`}
                style={{ minWidth: 200 }}
                disabled={grupos.length === 0 || exportingCsv}
              >
                Exportar CSV
              </button>
            </div>
            <div className="col-md-6">
              <button
                type="button"
                onClick={() =>
                  navigate(`/eventos/${eventoId}/ingressos/cadastrar-grupo`)
                }
                className="bc-btn bc-btn-primary px-3 py-2 w-100 h-100"
                style={{ minWidth: 200 }}
              >
                Adicionar grupo
              </button>
            </div>
          </div>
        </div>
      </WhiteContainer>

      {loading && (
        <WhiteContainer containerWidth={12}>
          <Loading container="50vh" />
        </WhiteContainer>
      )}

      {!loading && grupos.length > 0 && (
        <WhiteContainer containerWidth={12} containerPadding={4}>
          {/* Banner Header */}
          <div className="row">
            <div className="d-none d-lg-block col-lg-12 mb-3">
              <div className="row">
                <div className="col-lg-2">
                  <p className="text-500-black-16 m-0">Sessão</p>
                </div>
                <div className="col-lg-3">
                  <p className="text-500-black-16 m-0">Nome do grupo</p>
                </div>
                <div className="col-lg-7">
                  <p className="text-500-black-16 m-0">Qtd. de Ingressos</p>
                </div>
              </div>
            </div>

            {grupos.map((grupo) => (
              <div className="col-lg-12" key={grupo.id}>
                <div className="card-cinza-claro p-3 mb-3">
                  <div className="row">
                    <div className="col-lg-2 align-self-center">
                      <p className="d-block d-lg-none text-500-black-16 m-0">
                        Sessão
                      </p>
                      {/* exibir a primeira sessão no formato: 21/06/2023 - 22:00 */}
                      <p className="text-500-darkest-14 m-0">
                        {grupo.sessoes.length > 0 ? (
                          <>
                            {moment(grupo.sessoes[0].data).format("DD/MM/YYYY")}{" "}
                            - {moment(grupo.sessoes[0].data).format("HH:mm")}
                          </>
                        ) : (
                          "Grupo sem sessões"
                        )}
                      </p>
                    </div>
                    <div className="col-lg-3 align-self-center">
                      <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                        Nome do grupo
                      </p>
                      <p className="text-500-darkest-14 text-break mb-3 mb-lg-0">
                        {grupo.nome}
                      </p>
                    </div>
                    <div className="d-none d-lg-block col-lg-1 align-self-center">
                      <p className="text-500-darkest-14 text-break m-0">
                        {grupo.ingressos.length}
                      </p>
                    </div>
                    <div className="col-lg-6 align-self-center">
                      <BotoesListagemGrupo
                        eventoId={eventoId!}
                        grupo={grupo}
                        exibindoGrupo={exibindoGrupo}
                        toggleExibicao={toggleExibicao}
                        abrirModalExcluirGrupo={abrirModalExcluirGrupo}
                      />
                    </div>
                  </div>
                </div>

                {exibindoGrupo === grupo.id && (
                  <IngressosDoGrupo
                    eventoId={eventoId!}
                    grupoId={grupo.id}
                    ingressos={grupo.ingressos}
                    updateSituacaoIngresso={() => getGrupos()}
                  />
                )}
              </div>
            ))}
          </div>
        </WhiteContainer>
      )}

      {!loading && grupos.length === 0 && (
        <WhiteContainer
          containerWidth={12}
          containerPadding={4}
          rowMarginBottom={4}
        >
          <div className="row g-0" style={{ minHeight: "50vh" }}>
            <div className="col-lg-12">
              <DarkGrayCard message="Você ainda não tem grupos por aqui!" />
            </div>
          </div>
        </WhiteContainer>
      )}

      {/*
        --------------------------------------------------------------------------
                                  Modal: Excluir Grupo                           
        --------------------------------------------------------------------------
      */}
      <PromptModal
        isOpen={modalExcluirGrupo !== null}
        close={() => setModalExcluirGrupo(null)}
        icon={<DangerModalIcon />}
        title="Excluir grupo"
        subtitle={`Tem certeza que deseja excluir o grupo "${grupoExcluir.nome}” ? Após o cancelamento seu grupo
        ficará inativo`}
        cancelText="Cancelar"
        cancelAction={() => setModalExcluirGrupo(null)}
        confirmText="Excluir"
        confirmAction={excluirGrupo}
      />
    </MainLayout>
  );
}
