import clsx from "clsx";
import { ElementType } from "react";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";

interface Props {
  href: string;
  title: string;
  icon: ElementType;
}

export default function CustomNavLink({ href, title, icon: Icon }: Props) {
  const resolved = useResolvedPath(href);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <NavLink
      to={href}
      className={({ isActive }) =>
        clsx(
          `nav-link
          d-flex
          flex-column flex-md-row
          justify-content-center justify-content-md-start
          align-items-center
          text-center
          px-2 px-md-0
          mt-0 mt-md-2`,
          !isActive && "bc-text-navbar",
          isActive && "text-white nav-link-active"
        )
      }
    >
      <Icon />
      <p className="m-0 mt-2 m-md-0 ms-md-3">{title}</p>
    </NavLink>
  );
}
