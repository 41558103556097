import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../../Components/Breadcrumb";
import MainLayout from "../../../../Components/Layouts/Main";
import WhiteContainer from "../../../../Components/WhiteContainer";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import AxiosClient from "../../../../Services/AxiosClient";
import toast from "react-hot-toast";
import Input from "../../../../Components/Inputs/Input";
import ValidationError from "../../../../Components/ValidationError";
import FormButtons from "../../../../Components/Form/FormButtons";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import SessaoModel from "../../../../Interfaces/Models/SessaoModel";
import Loading from "../../../../Components/Loading";
import useObterSessoesGrupo from "../../../../Hooks/useObterSessoesGrupo";
import SessoesGrupo from "../Components/SessoesGrupo";
import AdicionarSessaoGrupo from "../Components/AdicionarSessaoGrupo";
import ServiceResult from "../../../../Interfaces/ServiceResult";
import GrupoModel from "../../../../Interfaces/Models/GrupoModel";

interface GruposPutBody {
  nome: string;
  quantidade: number;
  sessoes: string[] | null;
}

export default function EditarGrupo() {
  const { eventoId, grupoId } = useParams();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "Eventos",
    },
    {
      link: `/eventos/${eventoId}/ingressos`,
      name: "Ingressos",
    },
    {
      link: `/eventos/${eventoId}/ingressos/editar-grupo/${grupoId}`,
      name: "editar grupo",
    },
  ];

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [modalEditarSessao, setModalEditarSessao] = useState<boolean>(false);

  const [sessoes, setSessoes] = useState<SessaoModel[] | null>(null);
  const [sessoesSelecionadas, setSessoesSelecionadas] = useState<string[]>([]);

  const { loadingSessoes, getSessoes } = useObterSessoesGrupo();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<GruposPutBody>({
    defaultValues: {
      nome: "",
      quantidade: 0,
      sessoes: null,
    },
  });

  const selecionarSessao = (sessao: string): void => {
    const sessoes = [...sessoesSelecionadas];
    const index = sessoes.indexOf(sessao);

    if (index > -1) {
      sessoes.splice(index, 1);
    } else {
      sessoes.push(sessao);
    }

    setSessoesSelecionadas(sessoes);
  };

  const editarGrupo = async (data: GruposPutBody): Promise<void> => {
    if (sessoesSelecionadas.length === 0) {
      toast.error("Selecione pelo menos uma sessão para editar o grupo!");
      return;
    }

    setLoading(true);

    let args: GruposPutBody = {
      nome: data.nome,
      quantidade: Number(data.quantidade),
      sessoes: sessoesSelecionadas.length > 0 ? sessoesSelecionadas : null,
    };

    toast.promise(
      AxiosClient.put(`/eventos/${eventoId}/grupos/${grupoId}`, args)
        .then(() => navigate(`/eventos/${eventoId}/ingressos`))
        .finally(() => setLoading(false)),
      {
        loading: "Editando grupo...",
        success: "Grupo editado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const obterSessoes = async (): Promise<void> => {
    const sessoes: SessaoModel[] | null = await getSessoes(eventoId!);
    setSessoes(sessoes);
  };

  const obterGrupoPorId = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<GrupoModel>>(
      `/eventos/${eventoId}/grupos/${grupoId}`
    )
      .then(({ data: { data } }) => {
        setValue("nome", data!.nome);
        setValue("quantidade", data!.quantidade);
        setSessoesSelecionadas(data!.sessoes.map((s) => s.id));
      })
      .catch((error) => {
        navigate(`/eventos/${eventoId}/ingressos`);
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    obterGrupoPorId();
    obterSessoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Editar grupo</p>
        </div>
      </WhiteContainer>

      <form onSubmit={handleSubmit(editarGrupo)}>
        <WhiteContainer containerPadding={4} rowMarginBottom={4}>
          <div className="row g-3">
            <div className="col-xl-4">
              <p className="text-500-dark-18 mb-2">Nome do grupo</p>
              <Input
                autoFocus
                name="nome"
                control={control}
                errors={errors}
                validation={{
                  required: "Informe o nome do grupo",
                }}
                placeholder="Informe o nome do grupo"
                disabled={loading || loadingSessoes}
              />
              {errors.nome && ValidationError(errors.nome)}
            </div>

            <div className="col-xl-8">
              <p className="text-500-dark-18 mb-2">Quantidade</p>
              <Input
                type="number"
                min={0}
                name="quantidade"
                control={control}
                errors={errors}
                validation={{
                  required: "Informe um valor acima de zero para a quantidade",
                  min: {
                    value: 1,
                    message: "Informe um valor acima de zero para a quantidade",
                  },
                  max: {
                    value: 100000000,
                    message:
                      "Informe um valor abaixo de 100.000.000 para a quantidade",
                  },
                }}
                disabled={loading || loadingSessoes}
                placeholder="Informe a quantidade"
              />
              {errors.quantidade && ValidationError(errors.quantidade)}
            </div>

            {loadingSessoes && (
              <div className="my-5">
                <Loading container="0" />
              </div>
            )}

            {!loadingSessoes && sessoes && sessoes.length > 0 && (
              <SessoesGrupo
                sessoes={sessoes}
                setSessoes={setSessoes}
                sessoesSelecionadas={sessoesSelecionadas}
                selecionarSessao={selecionarSessao}
              />
            )}

            <AdicionarSessaoGrupo
              eventoId={eventoId!}
              modalEditarSessao={modalEditarSessao}
              setModalEditarSessao={setModalEditarSessao}
              obterSessoes={obterSessoes}
            />
          </div>
        </WhiteContainer>

        <FormButtons
          cancelAction={() => navigate(-1)}
          submitText="Editar grupo"
          disabled={loading}
        />
      </form>
    </MainLayout>
  );
}
