import PagamentoStatus from "../Enums/PagamentoStatus";

export default function PagamentoStatusToString(status: PagamentoStatus) {
  switch (status) {
    case PagamentoStatus.AguardandoPagamento:
      return "Aguardando pagamento";
    case PagamentoStatus.Devolvido:
      return "Devolvido";
    case PagamentoStatus.Pago:
      return "Pago";
    case PagamentoStatus.Processando:
      return "Processando";
    case PagamentoStatus.Recusado:
      return "Recusado";
    default:
      return "Status não encontrado";
  }
}
