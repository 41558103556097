import { useNavigate } from "react-router-dom";
import BreadCrumb, { Page } from "../../Components/Breadcrumb";
import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DangerModalIcon,
  DeleteIcon,
} from "../../Components/Icons";
import MainLayout from "../../Components/Layouts/Main";
import { useEffect, useState } from "react";
import AxiosClient from "../../Services/AxiosClient";
import toast from "react-hot-toast";
import Loading from "../../Components/Loading";
import BannerModel from "../../Interfaces/Models/BannerModel";
import AxiosErrorHandler from "../../Services/AxiosErrorHandler";
import WhiteContainer from "../../Components/WhiteContainer";
import PromptModal from "../../Components/Modals/PromptModal";
import DarkGrayCard from "../../Components/DarkGrayCard";

const breadCrumbHistory: Page[] = [
  {
    link: "/",
    name: "dashboard",
  },
  {
    link: "/banners",
    name: "banners",
  },
];

export default function Banners() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [banners, setBanners] = useState<BannerModel[] | null>(null);
  const [bannerSelected, setBannerSelected] = useState<BannerModel | null>(
    null
  );
  const [visualizandoBanner, setVisualizandoBanner] = useState<string | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const getBanners = async (): Promise<void> => {
    AxiosClient.get("/banners")
      .then(({ data: { data } }) => {
        // ordenar banners por data de atualização
        data.sort((a: BannerModel, b: BannerModel) => {
          return (
            new Date(b.ultimaAtualizacaoEm).getTime() -
            new Date(a.ultimaAtualizacaoEm).getTime()
          );
        });

        setBanners(data);
      })
      .catch((error: any) => {
        toast.error(
          error.response.data.messages.map((m: any) => m.message).join("\n")
        );
      })
      .finally(() => setLoading(false));
  };

  const toggleVisualizacao = (bannerId: string) => {
    if (visualizandoBanner === bannerId) {
      setVisualizandoBanner(null);
    } else {
      setVisualizandoBanner(bannerId);
    }
  };

  const handleDeletePrompt = (banner: BannerModel) => {
    setBannerSelected(banner);
    setIsModalOpen(true);
  };

  const handleDeleteBanner = async () => {
    setIsModalOpen(false);

    toast.promise(
      AxiosClient.delete(`/banners/${bannerSelected?.id}`).then(() => {
        setBanners(banners?.filter((b) => b.id !== bannerSelected?.id) || []);
        setVisualizandoBanner(null);
        setBannerSelected(null);
      }),
      {
        loading: "Excluindo banner...",
        success: "Banner excluído com sucesso!",
        error: (error: any) => AxiosErrorHandler(error),
      }
    );
  };

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4}>
        <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center">
          <p className="title-h1 text-black mb-2">Meus banners</p>
          {!loading && banners && (
            <button
              className="bc-btn bc-btn-primary py-2 px-3 d-flex justify-content-center align-items-center"
              onClick={() => navigate("/banners/cadastrar")}
            >
              <p className="text-500-white-16 pe-2 m-0">Cadastrar banner</p>
              <AddIcon />
            </button>
          )}
        </div>

        {!loading && !banners && (
          <DarkGrayCard
            message="Você ainda não tem banners por aqui!"
            action={() => navigate("/banners/cadastrar")}
            actionText="Cadastrar banner"
          />
        )}
      </WhiteContainer>

      {loading && (
        <WhiteContainer>
          <Loading container="50vh" />
        </WhiteContainer>
      )}

      {!loading && banners && (
        <WhiteContainer containerPadding={4} rowMarginBottom={4}>
          {/* Banner Header */}
          <div className="row">
            <div className="d-none d-lg-block col-lg-12 mb-3">
              <div className="row">
                <div className="col-sm-3">
                  <p className="text-500-black-16 m-0">Data</p>
                </div>
                <div className="col-sm-9">
                  <p className="text-500-black-16 m-0">Nome</p>
                </div>
              </div>
            </div>

            {banners.map((b) => (
              <div key={b.id} className="col-lg-12">
                <div className="card-cinza-claro p-3 mb-3">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="d-block d-lg-none text-500-black-16 m-0">
                        Data
                      </p>
                      <p className="text-500-dark-14 text-break mb-2">
                        {new Date(b.ultimaAtualizacaoEm).toLocaleDateString(
                          "pt-br",
                          {
                            day: "2-digit",
                            month: "numeric",
                            year: "numeric",
                          }
                        )}
                      </p>
                    </div>
                    <div className="col-sm-4">
                      <p className="d-block d-lg-none text-500-black-16 m-0">
                        Nome
                      </p>
                      <p className="text-500-dark-14 text-break mb-2">
                        {b.nome}
                      </p>
                    </div>
                    <div className="col-sm-5 d-flex flex-wrap justify-content-evenly align-items-center">
                      <button
                        onClick={() => toggleVisualizacao(b.id)}
                        className={`my-2 bc-btn ${
                          visualizandoBanner === b.id
                            ? "bc-btn-primary"
                            : "bc-btn-light"
                        } d-flex justify-content-center align-items-center rounded-pill`}
                        style={{
                          width: 144,
                          height: 24,
                        }}
                      >
                        {visualizandoBanner === b.id ? (
                          <>
                            <p className="m-0 me-2">Fechar</p>
                            <ChevronUpIcon />
                          </>
                        ) : (
                          <>
                            <p className="m-0 me-2">Visualizar</p>
                            <ChevronDownIcon />
                          </>
                        )}
                      </button>
                      <button
                        className="my-2 bc-btn bc-btn-light d-flex justify-content-center align-items-center rounded-pill"
                        style={{
                          width: 144,
                          height: 24,
                        }}
                        onClick={() => handleDeletePrompt(b)}
                      >
                        <p className="m-0 me-2">Excluir</p>
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                </div>

                {visualizandoBanner === b.id && (
                  <div
                    className="card-cinza-claro p-3 mb-3"
                    style={{
                      marginTop: "-12px",
                    }}
                  >
                    <div className="bg-white shadow-sm p-3">
                      <div className="row">
                        <div className="col-xl-8">
                          <p className="title-h1 text-black mb-4">
                            Detalhes do banner
                          </p>

                          <p className="text-500-dark-18">Nome do banner</p>
                          <input
                            type="text"
                            className="form-control backstage-input mb-4"
                            style={{ height: 40 }}
                            value={b.nome}
                            disabled
                            placeholder="Inserir nome do banner"
                          />
                          <div className="card-cinza-claro p-3 mb-4">
                            <div className="form-check form-check-inline d-flex align-items-center">
                              <input
                                className="form-check-input cursor-pointer"
                                type="checkbox"
                                id="exibicao_temporaria"
                                // value="option1"
                                style={{
                                  width: 24,
                                  height: 24,
                                }}
                                disabled
                              />
                              <label
                                className="form-check-label text-400-dark-16 mt-1 ps-2 cursor-pointer"
                                htmlFor="exibicao_temporaria"
                              >
                                Exibição temporária
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4">
                          <p className="text-500-dark-18 mt-xl-5 mb-2">
                            Banner do evento
                          </p>
                          <div className="my-4">
                            <img
                              src={b.imagem}
                              alt="upload banner img"
                              width="100%"
                              height="100%"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </WhiteContainer>
      )}

      <PromptModal
        icon={<DangerModalIcon />}
        title="Excluir banner"
        subtitle={`Tem certeza que deseja excluir o banner "${bannerSelected?.nome}" ?`}
        cancelText="Não excluir banner"
        cancelAction={() => setIsModalOpen(false)}
        confirmText="Excluir banner"
        confirmAction={handleDeleteBanner}
        close={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
    </MainLayout>
  );
}
