import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NotFound from "../Components/NotFound";
import Home from "../Pages/Home";
import Eventos from "../Pages/Eventos";
import Banners from "../Pages/Banners";
import Estatisticas from "../Pages/Eventos/Estatisticas";
import Ingressos from "../Pages/Eventos/Ingressos";
import Login from "../Pages/Login";
import PrivateRoute from "../Components/PrivateRoute";
import CadastrarBanner from "../Pages/Banners/Cadastrar";
import CadastrarEvento from "../Pages/Eventos/Cadastrar";
import EditarEvento from "../Pages/Eventos/Editar";
import CadastrarGrupo from "../Pages/Eventos/Ingressos/CadastrarGrupo";
import EditarGrupo from "../Pages/Eventos/Ingressos/EditarGrupo";
import CadastrarIngresso from "../Pages/Eventos/Ingressos/CadastrarIngresso";
import EditarIngresso from "../Pages/Eventos/Ingressos/EditarIngresso";
import CodigoPromocional from "../Pages/Eventos/CodigoPromocional";
import CadastrarCodigo from "../Pages/Eventos/CodigoPromocional/Cadastrar";
import EditarCodigo from "../Pages/Eventos/CodigoPromocional/Editar";
import Pedidos from "../Pages/Eventos/Pedidos";
import Participantes from "../Pages/Eventos/Participantes";
import Coprodutores from "../Pages/Eventos/Coprodutores";
import EsqueciSenha from "../Pages/EsqueciSenha";
import RedefinirSenha from "../Pages/RedefinirSenha";
import Usuarios from "../Pages/Usuarios";
import EditarUsuario from "../Pages/Usuarios/Editar";

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/esqueci-senha" element={<EsqueciSenha />} />
        <Route path="/redefinir-senha/:token" element={<RedefinirSenha />} />

        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />

        <Route path="/eventos"
               element={<PrivateRoute><Eventos /></PrivateRoute>} />
        <Route path="/cadastrar-evento"
               element={<PrivateRoute><CadastrarEvento /></PrivateRoute>} />
       <Route path="/eventos/:eventoId/editar"
              element={<PrivateRoute><EditarEvento /></PrivateRoute>} />

        <Route path="/eventos/:eventoId/estatisticas"
               element={<PrivateRoute><Estatisticas /></PrivateRoute>} />

        <Route path="/eventos/:eventoId/ingressos/cadastrar-grupo"
               element={<PrivateRoute><CadastrarGrupo /></PrivateRoute>} />
        <Route path="/eventos/:eventoId/ingressos/editar-grupo/:grupoId"
               element={<PrivateRoute><EditarGrupo /></PrivateRoute>} />

        <Route path="/eventos/:eventoId/ingressos"
               element={<PrivateRoute><Ingressos /></PrivateRoute>} />
        <Route path="/eventos/:eventoId/ingressos/grupo/:grupoId/cadastrar-ingresso"
              element={<PrivateRoute><CadastrarIngresso /></PrivateRoute>} />
        <Route path="/eventos/:eventoId/ingressos/grupo/:grupoId/editar-ingresso/:ingressoId"
               element={<PrivateRoute><EditarIngresso /></PrivateRoute>} />

        <Route path="/eventos/:eventoId/codigo-promocional"
               element={<PrivateRoute><CodigoPromocional /></PrivateRoute>} />
        <Route path="/eventos/:eventoId/codigo-promocional/cadastrar-codigo"
               element={<PrivateRoute><CadastrarCodigo /></PrivateRoute>} />
        <Route path="/eventos/:eventoId/codigo-promocional/editar-codigo/:codigoId"
               element={<PrivateRoute><EditarCodigo /></PrivateRoute>} />

        <Route path="/eventos/:eventoId/pedidos"
               element={<PrivateRoute><Pedidos /></PrivateRoute>} />

        <Route path="/eventos/:eventoId/participantes"
               element={<PrivateRoute><Participantes /></PrivateRoute>} />
        {/* <Route path="/eventos/:eventoId/participantes/editar/:participanteId"
               element={<PrivateRoute><Participantes /></PrivateRoute>} /> */}

        <Route path="/eventos/:eventoId/coprodutores"
              element={<PrivateRoute><Coprodutores /></PrivateRoute>} />

        <Route path="/banners"
               element={<PrivateRoute><Banners /></PrivateRoute>} />
        <Route path="/banners/cadastrar"
               element={<PrivateRoute><CadastrarBanner /></PrivateRoute>} />

        <Route path="/usuarios"
               element={<PrivateRoute><Usuarios /></PrivateRoute>} />
        <Route path="/usuarios/:usuarioId/editar"
               element={<PrivateRoute><EditarUsuario /></PrivateRoute>} />

        {/* 404 pages */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
