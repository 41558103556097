import { Link, useParams, useResolvedPath } from "react-router-dom";
import LogoSvgSemiWhite from "./Logo/LogoSvgSemiWhite";
import LogoutIcon from "./Icons/LogoutIcon";
import CustomNavLink from "./CustomNavLink";
import {
  ArrowLeftIcon,
  EstatisticasIcon,
  IngressoIcon,
  CodigoPromocionalIcon,
  PedidosIcon,
  ParticipantesIcon,
  CoprodutoresIcon,
  ConfiguracoesIcon,
} from "./Icons";

interface Props {
  responsiveCss: string;
  logout: () => void;
}

export default function NavMenuEvento({ responsiveCss, logout }: Props) {
  const { eventoId } = useParams();

  const path = useResolvedPath(`/eventos/${eventoId}`).pathname;

  const items = [
    {
      url: `${path}/estatisticas`,
      name: "Estatísticas",
      icon: EstatisticasIcon,
    },
    { url: `${path}/ingressos`, name: "Ingressos", icon: IngressoIcon },
    {
      url: `${path}/codigo-promocional`,
      name: "Código promocional",
      icon: CodigoPromocionalIcon,
    },
    { url: `${path}/pedidos`, name: "Pedidos", icon: PedidosIcon },
    {
      url: `${path}/participantes`,
      name: "Participantes",
      icon: ParticipantesIcon,
    },
    {
      url: `${path}/coprodutores`,
      name: "Coprodutores",
      icon: CoprodutoresIcon,
    },
    {
      url: `${path}/editar`,
      name: "Editar evento",
      icon: ConfiguracoesIcon,
    },
  ];

  return (
    <nav
      className={`${responsiveCss} d-sm-flex navbar align-items-start sidebar accordion bg-navmenu-whitelabel p-0`}
    >
      <div className="container-fluid d-flex flex-column min-vh-100 h-100 px-3">
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          <Link
            to="/"
            className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0 p-0"
          >
            <div className="sidebar-brand-icon">
              <div style={{ width: "100%", maxWidth: 120, maxHeight: 48 }}>
                <LogoSvgSemiWhite />
              </div>
            </div>
          </Link>
          <hr className="sidebar-divider my-0" />
          <ul className="navbar-nav w-100" id="accordionSidebar">
            {/* Menu para tela inicial */}
            <Link
              to="/eventos"
              className="nav-link
              d-flex
              flex-column flex-md-row
              justify-content-center justify-content-md-start
              align-items-center
              text-center
              bc-text-navbar
              px-2 px-md-0
              "
            >
              <ArrowLeftIcon />
              <p
                className="m-0 mt-2 m-md-0 ms-md-3 fw-semibold"
                style={{ lineHeight: "normal" }}
              >
                Voltar para eventos
              </p>
            </Link>
            {items.map((p, index) => (
              <CustomNavLink
                key={index}
                href={p.url}
                title={p.name}
                icon={p.icon}
              />
            ))}
          </ul>
        </div>
        <div className="mt-5 mx-2 d-flex flex-column align-items-center">
          <button
            className="bc-btn bc-btn-white mb-4 px-3 px-md-4 py-2 d-flex flex-wrap justify-content-center align-items-center"
            onClick={logout}
            style={{
              maxWidth: 216,
              minHeight: 56,
            }}
          >
            <div className="d-none d-md-flex">Sair da conta &nbsp;</div>
            <LogoutIcon />
          </button>
          <p className="text-500-white-14 text-center mb-4">V.2. Tickets App</p>
        </div>
      </div>
    </nav>
  );
}
