import MainLayout from "../../Components/Layouts/Main";
import { Link, useNavigate } from "react-router-dom";
import DarkGrayCard from "../../Components/DarkGrayCard";
import { useEffect, useState } from "react";
import { EstatisticasModel, EventoCardModel } from "../../Interfaces/Models";
import AxiosClient from "../../Services/AxiosClient";
import { RolagemInfinita, ServiceResult } from "../../Interfaces";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../Services/AxiosErrorHandler";
import CardEvento from "../../Components/CardEvento";
import Loading from "../../Components/Loading";
import Button from "../../Components/Button";
import { useUserStore } from "../../Contexts/UserStore";

export default function Home() {
  const navigate = useNavigate();
  const { nome } = useUserStore();

  const [loadingEstatisticas, setLoadingEstatisticas] = useState<boolean>(true);
  const [loadingEventos, setLoadingEventos] = useState<boolean>(true);
  const [estatisticas, setEstatisticas] = useState<EstatisticasModel | null>(
    null,
  );
  const [eventos, setEventos] = useState<EventoCardModel[]>([]);
  const [tabs, setTabs] = useState<{ name: string; active: boolean }[]>([
    { name: "Todos os eventos", active: true },
    { name: "Eventos do mês", active: false },
    { name: "Eventos da semana", active: false },
    { name: "Eventos do dia", active: false },
  ]);

  const toggleTab = (tab: string) => {
    setTabs((prev) =>
      prev.map((t) => ({
        ...t,
        active: t.name === tab,
      })),
    );

    setEventos([]);
  };

  const getRequestPathWIthQueryParams = (): string => {
    let url: string = "/eventos";
    url += `?pagina=1`;
    url += "&paginaTamanho=12";
    url += "&sort_by=-dataInicio";
    url += "&apenasComIngressosDisponiveis=false";
    url += "&apenasVisiveis=false";

    const today = new Date();

    for (const tab of tabs) {
      if (tab.active) {
        if (tab.name === "Todos os eventos") break;

        if (tab.name === "Eventos do mês") {
          // dataInicio = primeiro dia do mês atual
          const startOfMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1,
          )
            .toISOString()
            .split("T")[0];

          // dataFim = último dia do mês atual
          const endOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0,
          )
            .toISOString()
            .split("T")[0];

          url += `&dataInicio=${startOfMonth}&dataFim=${endOfMonth}`;
        }

        if (tab.name === "Eventos da semana") {
          // Define o início da semana para domingo
          const startOfWeek = new Date(today);
          startOfWeek.setDate(today.getDate() - today.getDay());
          startOfWeek.setHours(0, 0, 0, 0);

          // Define o final da semana para sábado
          const endOfWeek = new Date(today);
          endOfWeek.setDate(today.getDate() + (6 - today.getDay()));
          endOfWeek.setHours(23, 59, 59, 999);

          url += `&dataInicio=${startOfWeek.toISOString()}&dataFim=${endOfWeek.toISOString()}`;
        }

        if (tab.name === "Eventos do dia") {
          // dataInicio = hoje
          const dataInicio = new Date(today);
          dataInicio.setHours(0, 0, 0, 0);

          // dataFim = final do dia de hoje
          const dataFim = new Date(today);
          dataFim.setHours(23, 59, 59, 999);

          url += `&dataInicio=${dataInicio.toISOString()}&dataFim=${dataFim.toISOString()}`;
        }
      }
    }

    return url;
  };

  const getEstatisticas = async (): Promise<void> => {
    setLoadingEstatisticas(true);

    AxiosClient.get<ServiceResult<EstatisticasModel>>("/estatisticas")
      .then(({ data: { data } }) => {
        setEstatisticas(data as EstatisticasModel);
      })
      .catch((error) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoadingEstatisticas(false));
  };

  const getEventos = async (): Promise<void> => {
    setLoadingEventos(true);

    AxiosClient.get<ServiceResult<RolagemInfinita<EventoCardModel>>>(
      getRequestPathWIthQueryParams(),
    )
      .then(({ data: { data } }) => {
        setEventos((prevValue) => [...prevValue, ...data!.registros]);
      })
      .catch((error: any) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoadingEventos(false));
  };

  useEffect(() => {
    getEstatisticas();
    getEventos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  return (
    <MainLayout>
      <div className="row mb-4">
        <p className="title-h1 text-black m-0">Olá{nome ? `, ${nome}` : ""}</p>
        <p className="m-0 text-500-dark-18">
          Hoje é um ótimo dia para novos eventos!
        </p>
      </div>

      <div className="row g-3 mb-3">
        <div className="col-md-6 col-lg-3">
          <div className="shadow-sm bg-white p-3 h-100 d-flex flex-column justify-content-between">
            <p className="text-500-black-18 m-0">Eventos Ativos</p>
            {loadingEstatisticas && (
              <div className="pt-3">
                <Loading />
              </div>
            )}
            <p className="text-number-card-home m-0 text-break">
              {!loadingEstatisticas &&
                estatisticas &&
                estatisticas.eventosAtivos}
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="shadow-sm bg-white p-3 h-100 d-flex flex-column justify-content-between">
            <p className="text-500-black-18 m-0">
              Ingressos vendidos essa semana
            </p>
            {loadingEstatisticas && (
              <div className="pt-3">
                <Loading />
              </div>
            )}
            <p className="text-number-card-home m-0 text-break">
              {!loadingEstatisticas &&
                estatisticas &&
                estatisticas.ingressosVendidosSemanaAtual}
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="shadow-sm bg-white p-3 h-100 d-flex flex-column justify-content-between">
            <p className="text-500-black-18 m-0">Transações</p>
            {loadingEstatisticas && (
              <div className="pt-3">
                <Loading />
              </div>
            )}
            <p className="text-number-card-home m-0 text-break">
              {!loadingEstatisticas && estatisticas && estatisticas.transacoes}
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="shadow-sm bg-white p-3 h-100 d-flex flex-column justify-content-between">
            <p className="text-500-black-18 m-0">Eventos finalizados</p>
            {loadingEstatisticas && (
              <div className="pt-3">
                <Loading />
              </div>
            )}
            <p className="text-number-card-home m-0 text-break">
              {!loadingEstatisticas &&
                estatisticas &&
                estatisticas.eventosFinalizados}
            </p>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="shadow-sm bg-white p-3">
            {/* Botões filtragem */}
            <div className="d-flex flex-column flex-xl-row justify-content-center justify-content-xl-between align-items-center">
              <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
                {tabs.map((tab, i) => (
                  <button
                    key={i}
                    className={`btn-tab me-3 mb-2 ${tab.active && "btn-tab-active"
                      }`}
                    onClick={() => toggleTab(tab.name)}
                  >
                    <p className="text-400-dark-16 m-0">{tab.name}</p>
                  </button>
                ))}
              </div>
            </div>

            {loadingEventos && <Loading container="30vh" />}

            {/* Eventos */}
            {!loadingEventos && eventos.length > 0 && (
              <div className="row g-3">
                {eventos.map((e) => (
                  <Link
                    to={`/eventos/${e.id}/estatisticas`}
                    key={e.id}
                    className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xxl-2 mt-4 mt-lg-5 d-flex justify-content-center text-decoration-none"
                  >
                    <CardEvento
                      imagemThumbEvento={e.imagemThumbEvento}
                      dataInicio={e.dataInicio}
                      titulo={e.titulo}
                    />
                  </Link>
                ))}

                <div className="col-lg-12 pt-5 d-flex justify-content-center">
                  <Button
                    text="Ver todos os eventos"
                    onClick={() => navigate("/eventos")}
                  />
                </div>
              </div>
            )}

            {!loadingEventos && eventos.length === 0 && (
              <div className="col-lg-8">
                <DarkGrayCard
                  message="Você ainda não tem eventos por aqui!"
                  action={() => navigate("/cadastrar-evento")}
                  actionText="Criar Evento"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
