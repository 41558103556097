import clsx from "clsx";
import ReactInputMask from "react-input-mask";

import { ComponentProps } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  RegisterOptions,
} from "react-hook-form";

interface Props extends Omit<ComponentProps<"input">, "ref"> {
  control: Control<any>;
  errors: FieldErrors;
  height?: "40px" | "56px";
  name: string;
  mask?: string;
  maskChar?: string;
  validation: RegisterOptions;
}

const Input: React.FC<Props> = ({
  className = "",
  control,
  errors,
  height = "40px",
  name,
  mask = "",
  maskChar = "",
  validation,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field }) => (
        <ReactInputMask
          id={field.name}
          name={field.name}
          mask={mask}
          maskChar={maskChar}
          className={clsx(
            `${className} form-control backstage-input`,
            errors[name] && "border border-danger text-danger"
          )}
          onChange={field.onChange}
          style={{
            height: height,
          }}
          value={field.value}
          {...rest}
        />
      )}
    />
  );
};

export default Input;
