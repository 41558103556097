import { CodigoAcessoModel } from "../../../../Interfaces/Models";
import BotoesListagemCodigo from "./BotoesListagemCodigo";

interface Props {
  codigos: CodigoAcessoModel[];
  eventoId: string;
  updateStatusCodigo: (codigo: CodigoAcessoModel) => void;
  abrirModalExcluirCodigo: (codigo: CodigoAcessoModel) => void;
}

export default function CodigosPromocionais({
  codigos,
  eventoId,
  updateStatusCodigo,
  abrirModalExcluirCodigo,
}: Props) {
  return (
    <div className="row">
      <div className="d-none d-lg-block col-lg-12 mb-3">
        <div className="row">
          <div className="col-lg-3">
            <p className="text-500-black-16 m-0">Código</p>
          </div>
          <div className="col-lg-3">
            <p className="text-500-black-16 m-0">Ingressos</p>
          </div>
          <div className="col-lg-6">
            <p className="text-500-black-16 m-0"></p>
          </div>
        </div>
      </div>

      {codigos.map((codigo) => (
        <div className="col-lg-12" key={codigo.id}>
          <div className="card-cinza-claro p-3 mb-3">
            <div className="row">
              <div className="col-lg-3 align-self-center">
                <p className="d-block d-lg-none text-500-black-16 m-0">
                  Código
                </p>
                <p className="text-500-darkest-14 m-0">{codigo.codigo}</p>
              </div>
              <div className="col-lg-3 align-self-center">
                <p className="d-block d-lg-none text-500-black-16 m-0">
                  Ingressos
                </p>
                <p className="text-500-darkest-14 m-0">
                  {codigo.quantidadeIngressosVendidos} ingressos vendidos
                </p>
              </div>
              <div className="col-lg-6 align-self-center">
                <BotoesListagemCodigo
                  codigo={codigo}
                  eventoId={eventoId}
                  updateStatusCodigo={updateStatusCodigo}
                  abrirModalExcluirCodigo={abrirModalExcluirCodigo}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
