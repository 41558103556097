import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export function FormatarDataDDMMYYYY(data: string) {
  // formato -> 21/06/2023
  return format(new Date(data), "dd/MM/yyyy", {
    locale: ptBR,
  });
}

export function FormatarDataHHMM(data: string) {
  // formato -> 16:22
  return format(new Date(data), "HH:mm", {
    locale: ptBR,
  });
}

export function FormatarDataItemPedido(data: string) {
  // formato -> Sábado - 20/05/2023 - 22:00
  return format(new Date(data), "EEEE - dd/MM/yyyy - HH:mm", {
    locale: ptBR,
  });
}

export function FormatarDataSessao(data: string): string {
  // formato -> Sábado, 01/07/2023, 22:15
  return format(new Date(data), "EEEE, dd/MM/yyyy, HH:mm", {
    locale: ptBR,
  });
}
