interface Props {
  totalVendas: number;
}

export default function CardVendas({ totalVendas }: Props) {
  return (
    <div className="d-flex flex-column card-cinza-escuro p-3 w-100 h-100">
      <div className="d-flex justify-content-between align-items-center">
        <p className="text-500-black-18 m-0">Pedidos</p>
        <SvgIconBarChart />
      </div>
      <div className="d-flex flex-column justify-content-between h-100">
        <p className="text-number-card-home text-black m-0">{totalVendas}</p>
        <SvgIconLineChart />
      </div>
    </div>
  );
}

const SvgIconBarChart = (): JSX.Element => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.3333 4H6.66667C5.2 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V6.66667C28 5.2 26.8 4 25.3333 4ZM10.6667 22.6667C9.93333 22.6667 9.33333 22.0667 9.33333 21.3333V14.6667C9.33333 13.9333 9.93333 13.3333 10.6667 13.3333C11.4 13.3333 12 13.9333 12 14.6667V21.3333C12 22.0667 11.4 22.6667 10.6667 22.6667ZM16 22.6667C15.2667 22.6667 14.6667 22.0667 14.6667 21.3333V10.6667C14.6667 9.93333 15.2667 9.33333 16 9.33333C16.7333 9.33333 17.3333 9.93333 17.3333 10.6667V21.3333C17.3333 22.0667 16.7333 22.6667 16 22.6667ZM21.3333 22.6667C20.6 22.6667 20 22.0667 20 21.3333V18.6667C20 17.9333 20.6 17.3333 21.3333 17.3333C22.0667 17.3333 22.6667 17.9333 22.6667 18.6667V21.3333C22.6667 22.0667 22.0667 22.6667 21.3333 22.6667Z"
      fill="#303F9F"
    />
  </svg>
);

const SvgIconLineChart = (): JSX.Element => (
  <svg
    className="d-flex align-self-end"
    style={{
      width: "100%",
      maxWidth: 198,
    }}
    height="116"
    viewBox="0 0 198 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M195 3C136.111 6.32109 141.335 90.7718 67.2486 78.4363C29.3937 72.1334 10.9636 75.5591 3 81"
      stroke="#303F9F"
      strokeWidth="5"
      strokeLinecap="round"
    />
    <path
      d="M197 4C137.191 7.32109 142.496 91.7718 67.2525 79.4363C28.8061 73.1334 9.08806 76.5591 1 82V116H197V4Z"
      fill="url(#paint0_linear_314_4467)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_314_4467"
        x1="99"
        y1="27"
        x2="99"
        y2="116"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5EBFF" />
        <stop offset="1" stopColor="#E5EBFF" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
