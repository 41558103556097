import { useEffect, useState } from "react";
import AxiosClient from "../../../../Services/AxiosClient";
import { ProdutorEventoModel } from "../../../../Interfaces/Models";
import { ServiceResult } from "../../../../Interfaces";
import AxiosErrorHandler from "../../../../Services/AxiosErrorHandler";
import toast from "react-hot-toast";
import WhiteContainer from "../../../../Components/WhiteContainer";
import DarkGrayCard from "../../../../Components/DarkGrayCard";
import BotoesListagemCoprodutores from "./BotoesListagemCoprodutores";
import PromptModal from "../../../../Components/Modals/PromptModal";
import { DangerModalIcon } from "../../../../Components/Icons";
import Loading from "../../../../Components/Loading";

interface Props {
  eventoId: string;
}

export default function ListagemCoprodutores({ eventoId }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [coprodutores, setCoprodutores] = useState<ProdutorEventoModel[]>([]);

  const [auxCoprodutorModel, setAuxCoprodutorModel] =
    useState<ProdutorEventoModel>({} as ProdutorEventoModel);

  const [modalExcluirCoprodutor, setModalExcluirCoprodutor] = useState<
    string | null
  >(null);

  const abrirModalExcluirCoprodutor = (participante: ProdutorEventoModel) => {
    setAuxCoprodutorModel(participante);
    setModalExcluirCoprodutor(participante.id);
  };

  const excluirCoprodutor = async (): Promise<void> => {
    setModalExcluirCoprodutor(null);

    toast.promise(
      AxiosClient.delete(
        `/eventos/${eventoId}/coprodutores/${auxCoprodutorModel.id}`
      )
        .then(() =>
          setCoprodutores((prev) =>
            prev.filter((c) => c.id !== auxCoprodutorModel.id)
          )
        )
        .finally(() => setAuxCoprodutorModel({} as ProdutorEventoModel)),
      {
        loading: "Excluindo coprodutor...",
        success: "Coprodutor excluído com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      }
    );
  };

  const getCoprodutores = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<ProdutorEventoModel[]>>(
      `/eventos/${eventoId}/coprodutores`
    )
      .then(({ data: { data } }) => {
        setCoprodutores(data as ProdutorEventoModel[]);
      })
      .catch((error) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getCoprodutores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WhiteContainer containerPadding={4} rowMarginBottom={4}>
      {loading && <Loading container="30vh" />}

      {!loading && coprodutores.length > 0 && (
        <div className="row">
          <div className="d-none d-lg-block col-lg-12 mb-3">
            <div className="row">
              <div className="col-lg-4">
                <p className="text-500-black-16 m-0">Nome</p>
              </div>
              <div className="col-lg-4">
                <p className="text-500-black-16 m-0">E-mail</p>
              </div>
            </div>
          </div>

          {coprodutores.map((c) => (
            <div className="col-lg-12" key={c.id}>
              <div className="card-cinza-claro p-3 mb-3">
                <div className="row">
                  <div className="align-self-center col-lg-4">
                    <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                      Nome
                    </p>
                    <p className="text-500-darkest-14 text-break m-0">
                      {c.nome}
                    </p>
                  </div>

                  <div className="align-self-center col-lg-4">
                    <p className="d-block d-lg-none text-500-black-16 m-0 mt-3">
                      Email
                    </p>
                    <p className="text-500-darkest-14 text-break m-0">
                      {c.email}
                    </p>
                  </div>

                  <div className="align-self-center col-lg-4">
                    <BotoesListagemCoprodutores
                      coprodutor={c}
                      abrirModalExcluirCoprodutor={abrirModalExcluirCoprodutor}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {!loading && coprodutores.length === 0 && (
        <div className="row">
          <div className="col-lg-8">
            <DarkGrayCard message="Você ainda não tem coprodutores por aqui!" />
          </div>
        </div>
      )}

      {/*
        --------------------------------------------------------------------------
                                 Modal: Excluir coprodutor                        
        --------------------------------------------------------------------------
      */}
      {modalExcluirCoprodutor && (
        <PromptModal
          isOpen={modalExcluirCoprodutor !== null}
          close={() => setModalExcluirCoprodutor(null)}
          icon={<DangerModalIcon />}
          title="Excluir coprodutor"
          subtitle={`Tem certeza que deseja excluir o coprodutor "${auxCoprodutorModel.nome}” do evento ?`}
          cancelText="Cancelar"
          cancelAction={() => setModalExcluirCoprodutor(null)}
          confirmText="Excluir"
          confirmAction={excluirCoprodutor}
        />
      )}
    </WhiteContainer>
  );
}
