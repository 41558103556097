import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import { useForm } from "react-hook-form";
import FormButtons from "../../../Components/Form/FormButtons";
import Input from "../../../Components/Inputs/Input";
import MainLayout from "../../../Components/Layouts/Main";
import ValidationError from "../../../Components/ValidationError";
import WhiteContainer from "../../../Components/WhiteContainer";
import toast from "react-hot-toast";
import AxiosClient from "../../../Services/AxiosClient";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import { useEffect, useState } from "react";
import { ServiceResult } from "../../../Interfaces";
import { UsuarioModel } from "../../../Interfaces/Models";
import Loading from "../../../Components/Loading";
import { SomenteNumeros } from "../../../Utils";

interface UsuariosPutArgs {
  nomeCompleto: string;
  email: string;
  telefone: string;
  telefonePaisId: 31;
  cpf: string;
  ativo: boolean;
}

interface UsuarioViewModel {
  nomeCompleto: string;
  email: string;
  telefone: string;
  cpf: string;
  ativo: "Sim" | "Não";
  emailConfirmado: "Sim" | "Não";
  telefoneConfirmado: "Sim" | "Não";
}

export default function EditarUsuario() {
  const navigate = useNavigate();

  const { usuarioId } = useParams();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/usuarios",
      name: "Usuários",
    },
    {
      link: `/usuarios/${usuarioId}/editar`,
      name: "Código promocional",
    },
  ];

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UsuarioViewModel>({
    defaultValues: {
      nomeCompleto: "",
      cpf: "",
      email: "",
      telefone: "",
      ativo: "Sim",
      emailConfirmado: "Sim",
      telefoneConfirmado: "Sim",
    },
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [usuario, setUsuario] = useState<UsuarioModel>({} as UsuarioModel);

  const obterUsuario = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<UsuarioModel>>(`/usuarios/${usuarioId}`)
      .then(({ data: { data } }) => {
        setUsuario(data as UsuarioModel);
        setValue("nomeCompleto", data?.nomeCompleto ?? "");
        setValue("email", data?.email ?? "");
        setValue("telefone", data?.telefone ?? "");
        setValue("cpf", data?.cpf ?? "");
        setValue("ativo", data!.ativo ? "Sim" : "Não");
        setValue("emailConfirmado", data!.emailConfirmado ? "Sim" : "Não");
        setValue(
          "telefoneConfirmado",
          data!.telefoneConfirmado ? "Sim" : "Não",
        );
      })
      .catch((error) => toast.error(AxiosErrorHandler(error)))
      .finally(() => setLoading(false));
  };

  const editarUsuario = async (data: UsuarioViewModel): Promise<void> => {
    let args: UsuariosPutArgs = {
      nomeCompleto: data.nomeCompleto,
      email: data.email,
      telefone: SomenteNumeros(data.telefone),
      telefonePaisId: 31,
      cpf: data.cpf,
      ativo: usuario.ativo,
    };

    toast.promise(
      AxiosClient.put(`/usuarios/${usuarioId}`, args).then(() => {
        navigate("/usuarios");
      }),
      {
        loading: "Editando usuário...",
        success: "Usuário editado com sucesso!",
        error: (error) => AxiosErrorHandler(error),
      },
    );
  };

  useEffect(() => {
    obterUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer containerPadding={4}>
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between align-items-center">
          <p className="title-h1 text-black text-center mb-2">Editar usuário</p>
        </div>
      </WhiteContainer>

      {loading && <Loading container="30vh" />}

      {!loading && (
        <form onSubmit={handleSubmit(editarUsuario)}>
          <WhiteContainer containerPadding={4} rowMarginBottom={4}>
            <div className="row g-3">
              <div className="col-xl-6">
                <p className="text-500-dark-18 mb-2">Nome</p>
                <Input
                  autoFocus
                  name="nomeCompleto"
                  control={control}
                  errors={errors}
                  validation={{
                    required: "Informe o nome completo",
                  }}
                  placeholder="Nome completo"
                />
                {errors.nomeCompleto && ValidationError(errors.nomeCompleto)}
              </div>

              <div className="col-xl-6">
                <p className="text-500-dark-18 mb-2">E-mail</p>
                <Input
                  name="email"
                  control={control}
                  errors={errors}
                  validation={{
                    required: "Informe o e-mail",
                  }}
                  placeholder="E-mail"
                />
                {errors.email && ValidationError(errors.email)}
              </div>

              <div className="col-xl-6">
                <p className="text-500-dark-18 mb-2">CPF</p>
                <Input
                  name="cpf"
                  control={control}
                  errors={errors}
                  validation={{
                    required: "Informe o CPF",
                  }}
                  placeholder="CPF"
                />
                {errors.cpf && ValidationError(errors.cpf)}
              </div>

              <div className="col-xl-6">
                <p className="text-500-dark-18 mb-2">Telefone</p>
                <Input
                  mask="+55 (99) 99999-9999"
                  maskChar="*"
                  name="telefone"
                  control={control}
                  errors={errors}
                  validation={{
                    required: "Informe o telefone",
                  }}
                  placeholder="Telefone"
                />
                {errors.telefone && ValidationError(errors.telefone)}
              </div>

              <div className="col-xl-4">
                <p className="text-500-dark-18 mb-2">Usuário ativo</p>
                <Input
                  type="text"
                  name="ativo"
                  control={control}
                  errors={errors}
                  validation={{}}
                  disabled
                  className="disabled"
                  placeholder="Ativo"
                />
              </div>

              <div className="col-lg-6 col-xl-4">
                <p className="text-500-dark-18 mb-2">E-mail confirmado</p>
                <Input
                  type="text"
                  name="emailConfirmado"
                  control={control}
                  errors={errors}
                  validation={{}}
                  disabled
                  className="disabled"
                  placeholder="E-mail confirmado"
                />
              </div>

              <div className="col-lg-6 col-xl-4">
                <p className="text-500-dark-18 mb-2">Telefone confirmado</p>
                <Input
                  type="text"
                  name="telefoneConfirmado"
                  control={control}
                  errors={errors}
                  validation={{}}
                  disabled
                  className="disabled"
                  placeholder="Telefone confirmado"
                />
              </div>
            </div>
          </WhiteContainer>

          <FormButtons
            submitText="Editar"
            cancelAction={() => navigate(`/usuarios`)}
          />
        </form>
      )}
    </MainLayout>
  );
}
