import { ChangeEvent, useEffect, useRef, useState } from "react";
import BreadCrumb, { Page } from "../../../Components/Breadcrumb";
import MainLayout from "../../../Components/Layouts/Main";
import WhiteContainer from "../../../Components/WhiteContainer";
import {
  DangerModalIcon,
  EditModalIcon,
  ImgPreviewIcon,
  UploadIcon,
} from "../../../Components/Icons";
import { EstadosBrasileiros, SomenteNumeros } from "../../../Utils";
import { useNavigate, useParams } from "react-router-dom";
import AxiosClient from "../../../Services/AxiosClient";
import toast from "react-hot-toast";
import AxiosErrorHandler from "../../../Services/AxiosErrorHandler";
import GeoJSONPoint from "../../../Interfaces/GeoJSONPoint";
import EventoSituacao from "../../../Enums/EventoSituacao";
import EnderecoModel from "../../../Interfaces/Models/EnderecoModel";
import { useForm } from "react-hook-form";
import Input from "../../../Components/Inputs/Input";
import ValidationError from "../../../Components/ValidationError";
import ViaCepModel from "../../../Interfaces/Models/ViaCepModel";
import useViaCep from "../../../Hooks/useViaCep";
import Loading from "../../../Components/Loading";
import PromptModal from "../../../Components/Modals/PromptModal";
import { EventoModel } from "../../../Interfaces/Models";
import { ServiceResult } from "../../../Interfaces";

interface EventosPutBody {
  titulo: string;
  descricao: string;
  exibirDescricao: boolean;
  endereco: EnderecoModel;
  idadeMinima: number | null;
  imagemBannerEventoBase64: string;
  imagemEventoBase64: string;
  imagemLocalEventoBase64: string | null;
  imagemThumbEventoBase64: string | null;
  linkSrcLocalizacaoMap: string | null;
  situacao: EventoSituacao;
  taxaAdministracao: number | null;
  localizacao: GeoJSONPoint | null;
  exibirLocalizacao: boolean;
  parcelamentoMaximo: number;
}

export default function EditarEvento() {
  const { eventoId } = useParams();

  const breadCrumbHistory: Page[] = [
    {
      link: "/",
      name: "dashboard",
    },
    {
      link: "/eventos",
      name: "eventos",
    },
    {
      link: `/eventos/${eventoId}/editar`,
      name: "editar evento",
    },
  ];

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      titulo: "",
      descricao: "",
      exibirDescricao: true,
      situacao: EventoSituacao.Ativo,
      idadeMinima: 18,
      taxaAdministracao: 0,
      linkSrcLocalizacaoMap: "",
      imagemBannerEventoBase64: "",
      imagemEventoBase64: "",
      exibirLocalizacao: true,
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      pais: "brasil",
      unidadeFederacao: "SP",
      cidade: "",
    },
  });

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [cancelEventModalOpen, setCancelEventModalOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [parcelamentoMaximo, setParcelamentoMaximo] = useState<number>(1);
  const [eventoModel, setEventoModel] = useState<EventoModel>(
    {} as EventoModel
  );

  const [exibirDescricao, setExibirDescricao] = useState<boolean>(true);
  const [descricao, setDescricao] = useState<string>("");
  const [situacao, setSituacao] = useState<string>("Ativo");
  const [exibirLocalizacao, setExibirLocalizacao] = useState<boolean>(true);
  const [bannerEvento, setBannerEvento] = useState<string | undefined>("");
  const [imagemEvento, setImagemEvento] = useState<string | undefined>("");
  const [estado, setEstado] = useState<string>("SP");

  const [formArgs, setFormArgs] = useState<any>({} as any);

  const [habilitarCustomizacaoTaxa, setHabilitarCustomizacaoTaxa] =
    useState<boolean>(true);

  const bannerEventoRef = useRef<HTMLInputElement | null>(null);
  const imagemEventoRef = useRef<HTMLInputElement | null>(null);

  const obterTotalParcelas = (): number[] => {
    const n = 12;
    let parcelas: number[] = [];

    for (let i = 1; i <= n; i++) {
      parcelas.push(i);
    }

    return parcelas;
  };

  const AlterarImagem = (
    e: ChangeEvent<HTMLInputElement>,
    img: "banner" | "evento"
  ): void => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const formData = new FormData();
      formData.append("files", file, file.name);

      // set base 64 img
      const reader = new FileReader();
      reader.onload = (e) => {
        img === "banner"
          ? setBannerEvento(e.target?.result as string)
          : setImagemEvento(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = (inputFile: "banner" | "imagem"): void => {
    if (loading) return;

    if (inputFile === "banner") {
      bannerEventoRef.current?.click();
    } else {
      imagemEventoRef.current?.click();
    }
  };

  const confirmarEdicaoEvento = (data: any): void => {
    if (viaCepErro) {
      toast.error("Informe um CEP válido para continuar.");
      return;
    }

    if (!bannerEvento) {
      toast.error("Insira o banner do evento.");
      return;
    }

    if (!imagemEvento) {
      toast.error("Insira a imagem do evento.");
      return;
    }

    setConfirmModalOpen(true);
    setFormArgs(data);
  };

  const editarEvento = async (): Promise<void> => {
    setLoading(true);
    setConfirmModalOpen(false);

    let args: EventosPutBody = {
      titulo: formArgs.titulo,
      descricao: descricao,
      exibirDescricao: exibirDescricao,
      situacao: situacao,
      idadeMinima: formArgs.idadeMinima,
      linkSrcLocalizacaoMap: formArgs.linkSrcLocalizacaoMap,
      exibirLocalizacao: exibirLocalizacao,
      endereco: {
        bairro: formArgs.bairro,
        cep: SomenteNumeros(formArgs.cep),
        cidade: formArgs.cidade,
        complemento: formArgs.complemento,
        logradouro: formArgs.logradouro,
        numero: formArgs.numero,
        pais: "brasil",
        unidadeFederacao: estado,
      },
      imagemBannerEventoBase64: bannerEvento?.includes("data:") ? bannerEvento.split(",")[1] : null,
      imagemEventoBase64: imagemEvento?.includes("data:") ? imagemEvento.split(",")[1] : null,
      parcelamentoMaximo: parcelamentoMaximo,
    } as EventosPutBody;

    if (habilitarCustomizacaoTaxa) {
      args.taxaAdministracao = formArgs.taxaAdministracao;
    }

    toast.promise(
      AxiosClient.put(`/eventos/${eventoId}`, args).finally(() => {
        setConfirmModalOpen(false);
        setLoading(false);
      }),
      {
        loading: "Editando evento...",
        success: "Evento editado com sucesso!",
        error: (error: any) => AxiosErrorHandler(error),
      }
    );
  };

  const confirmarCancelarEvento = (): void => {
    setCancelEventModalOpen(true);
  };

  const cancelarEvento = async (): Promise<void> => {
    setLoading(true);
    setCancelEventModalOpen(false);

    // TODO: CANCELAR EVENTO
    const interval = setInterval(() => {
      setLoading(false);
      toast.success("Seu evento foi cancelado com sucesso!");
      navigate("/eventos");
      clearInterval(interval);
    }, 300);
  };

  const getEvento = async (): Promise<void> => {
    setLoading(true);

    AxiosClient.get<ServiceResult<EventoModel>>(`/eventos/${eventoId}`)
      .then(({ data: { data } }) => {
        const rngNumber = Math.floor(Math.random() * 1000) + 1;
        data!.imagemEvento = `${data?.imagemEvento}?updateCache=${rngNumber}`
        data!.imagemBannerEvento = `${data?.imagemBannerEvento}?updateCache=${rngNumber}`

        setEventoModel(data as EventoModel);
        setPageData(data);
      })
      .catch((error: any) => {
        navigate("/eventos");
        toast.error(AxiosErrorHandler(error));
      })
      .finally(() => setLoading(false));
  };

  const setPageData = (data: any): void => {
    setValue("titulo", data.titulo);
    setValue("descricao", data.descricao);
    setExibirDescricao(data.exibirDescricao);
    setDescricao(data.descricao);
    setSituacao(data.situacao);
    setValue("idadeMinima", data.idadeMinima);
    setValue("taxaAdministracao", data.taxaAdministracao);
    setValue("linkSrcLocalizacaoMap", data.linkSrcLocalizacaoMap);
    setExibirLocalizacao(data.exibirLocalizacao);

    // set cep format 00000-000
    const formatedCep: string = data.endereco?.cep?.replace(
      /^(\d{5})(\d{3})$/,
      "$1-$2"
    );
    setValue("cep", formatedCep);

    setValue("logradouro", data.endereco?.logradouro);
    setValue("numero", data.endereco?.numero);
    setValue("complemento", data.endereco?.complemento);
    setValue("bairro", data.endereco?.bairro);
    setValue("cidade", data.endereco?.cidade);
    setEstado(data.endereco?.unidadeFederacao);

    setBannerEvento(data.imagemBannerEvento);
    setImagemEvento(data.imagemEvento);

    if (data.endereco) {
      setViaCepErro(false);
      setBuscouCep(true);
    }

    if (data.taxaAdministracao) {
      setHabilitarCustomizacaoTaxa(true);
    } else {
      setHabilitarCustomizacaoTaxa(false);
    }

    setParcelamentoMaximo(data.parcelamentoMaximo)
  };

  useEffect(() => {
    getEvento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region CEP

  const { FetchCep, fetchingCep } = useViaCep();

  const cep: string = watch("cep");

  const [buscouCep, setBuscouCep] = useState<boolean>(false);
  const [viaCepErro, setViaCepErro] = useState<boolean>(true);

  const buscouCepInvalido = !fetchingCep && viaCepErro && buscouCep;
  const buscouCepValido = !fetchingCep && !viaCepErro && buscouCep;
  const naoBuscouCep = !fetchingCep && viaCepErro && !buscouCep;

  const BuscarCep = async (): Promise<void> => {
    if (cep?.length > 8) {
      const viaCepResponse: ViaCepModel = await FetchCep(cep);

      setBuscouCep(true);

      if (viaCepResponse.erro) {
        toast.error("CEP inválido! Não foi possível obter o endereço.");
        setViaCepErro(true);

        setValue("logradouro", "");
        setValue("complemento", "");
        setValue("bairro", "");
        setValue("cidade", "");
        setEstado("SP");
      } else {
        setViaCepErro(false);

        setValue("cep", viaCepResponse.cep);
        setValue("logradouro", viaCepResponse.logradouro);
        setValue("complemento", viaCepResponse.complemento);
        setValue("bairro", viaCepResponse.bairro);
        setValue("cidade", viaCepResponse.localidade);
        setEstado(viaCepResponse.uf?.toUpperCase());
      }
    }
  };

  useEffect(() => {
    BuscarCep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cep]);

  //#endregion

  return (
    <MainLayout>
      <div className="mb-3">
        <BreadCrumb history={breadCrumbHistory} />
      </div>

      <WhiteContainer>
        <p className="title-h1 text-black mb-2">Editar evento</p>
      </WhiteContainer>

      {loading ? (
        <>
          <WhiteContainer>
            <Loading container="30vh" />
          </WhiteContainer>
          <WhiteContainer>
            <Loading container="50vh" />
          </WhiteContainer>
        </>
      ) : (
        <form onSubmit={handleSubmit(confirmarEdicaoEvento)}>
          <WhiteContainer>
            <p className="title-h1 text-black mb-3">Informações do evento</p>

            <div className="mb-4">
              <p className="text-500-dark-18 mb-2">Título do evento</p>
              <Input
                autoFocus
                name="titulo"
                control={control}
                errors={errors}
                validation={{
                  required: "Informe o título do evento",
                }}
                placeholder="Título do evento"
              />
              {errors.titulo && ValidationError(errors.titulo)}
            </div>

            <div className="mb-3">
              <div className="d-flex flex-wrap-reverse justify-content-between align-items-center">
                <p className="text-500-dark-18 mb-2">Descrição do evento</p>

                <div className="form-check form-switch d-flex align-items-center ps-5 mb-2">
                  <input
                    className="form-check-input mb-1"
                    style={{ width: "3.3em", height: "1.7em" }}
                    type="checkbox"
                    role="switch"
                    id="exibirDescricao"
                    name="exibirDescricao"
                    checked={exibirDescricao}
                    onChange={({ target: { checked } }) =>
                      setExibirDescricao(checked)
                    }
                  />
                  <label
                    className="form-check-label ps-2"
                    htmlFor="exibirDescricao"
                  >
                    Habilitar descrição
                  </label>
                </div>
              </div>

              <textarea
                name="descricao"
                placeholder="Descrição do evento"
                className={`form-control backstage-input p-2 ${
                  !exibirDescricao && "disabled"
                }`}
                style={{ height: 120, resize: "none" }}
                disabled={!exibirDescricao}
                value={descricao}
                onChange={({ target: { value } }) => setDescricao(value)}
              />
            </div>
          </WhiteContainer>

          <WhiteContainer>
            <p className="title-h1 text-black mb-3">Informações adicionais</p>

            {/* Situação + Idade mínima */}
            <div className="row g-0 mb-4">
              {/* 
                --------------------------------------------------------------------------
                                            Situação do evento                            
                --------------------------------------------------------------------------
              */}
              <div className="col-lg-6 mb-3 mb-xl-0">
                <p className="text-500-dark-18 mb-3">Situação do evento</p>

                {Object.values(EventoSituacao).map((s) => (
                  <div className="mb-2" key={s}>
                    <input
                      type="radio"
                      name="situacao"
                      id={`situacao_${s}`}
                      value={s}
                      checked={situacao === s}
                      onChange={() => setSituacao(s)}
                    />
                    <label htmlFor={`situacao_${s}`} className="ps-2">
                      {s}
                    </label>
                  </div>
                ))}
              </div>

              {/* 
                --------------------------------------------------------------------------
                                          Idade mínima permitida                          
                --------------------------------------------------------------------------
              */}
              <div className="col-lg-6">
                <p className="text-500-dark-18 mb-2">Idade mínima permitida</p>
                <Input
                  name="idadeMinima"
                  control={control}
                  errors={errors}
                  validation={{
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Informe apenas números",
                    },
                  }}
                  placeholder="Idade mínima"
                />
                {errors.idadeMinima && ValidationError(errors.idadeMinima)}
              </div>
            </div>

            {/* Banner + Imagem do evento */}
            <div className="row g-0 mb-4">
              {/* 
                --------------------------------------------------------------------------
                                              Banner Evento                              
                --------------------------------------------------------------------------
              */}
              <div className="col-lg-6 mb-4">
                <p className="text-500-dark-18 mb-2">Banner do evento</p>
                <p className="text-400-black-16 mb-2 me-lg-4">
                  Recomendamos imagens em 650x350 px. Tamanho máximo 2MB.
                </p>

                <div className="my-4 me-lg-4">
                  {/* Input file hidden */}
                  <input
                    ref={bannerEventoRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={(e) => AlterarImagem(e, "banner")}
                  />
                  <div
                    className="cursor-pointer text-center"
                    onClick={() => handleUploadClick("banner")}
                  >
                    {bannerEvento ? (
                      <img
                        src={bannerEvento}
                        alt="upload banner img"
                        width="100%"
                        height="auto"
                      />
                    ) : (
                      <ImgPreviewIcon />
                    )}
                  </div>
                </div>

                <button
                  type="button"
                  className="
                    bc-btn 
                    bc-btn-primary 
                    py-2 px-3 
                    mx-auto
                    d-flex 
                    justify-content-center 
                    align-items-center
                    "
                  onClick={() => handleUploadClick("banner")}
                >
                  <p className="text-500-white-16 pe-2 m-0">
                    {bannerEvento ? "Trocar" : "Inserir"} banner
                  </p>
                  <UploadIcon />
                </button>
              </div>

              {/* 
                --------------------------------------------------------------------------
                                              Imagem Evento                              
                --------------------------------------------------------------------------
              */}
              <div className="col-lg-6">
                <p className="text-500-dark-18 mb-2">Imagem do evento</p>
                <p className="text-400-black-16 mb-2">
                  Recomendamos imagens em 320x150 px. Tamanho máximo 2MB.
                </p>

                <div className="my-4">
                  {/* Input file hidden */}
                  <input
                    ref={imagemEventoRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={(e) => AlterarImagem(e, "evento")}
                  />
                  <div
                    className="cursor-pointer text-center"
                    onClick={() => handleUploadClick("imagem")}
                  >
                    {imagemEvento ? (
                      <img
                        src={imagemEvento}
                        alt="upload evento img"
                        style={{
                          maxWidth: "350px",
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    ) : (
                      <ImgPreviewIcon />
                    )}
                  </div>
                </div>

                <button
                  type="button"
                  className="
                    bc-btn 
                    bc-btn-primary 
                    py-2 px-3 
                    mx-auto
                    d-flex 
                    justify-content-center 
                    align-items-center
                    "
                  onClick={() => handleUploadClick("imagem")}
                >
                  <p className="text-500-white-16 pe-2 m-0">
                    {imagemEvento ? "Trocar" : "Inserir"} imagem
                  </p>
                  <UploadIcon />
                </button>
              </div>
            </div>

            {/* Taxa de administração */}
            <div className="row g-3 mb-4">
              <div className="col-lg-4">
                <p className="text-500-dark-18 mb-2">Taxa de administração</p>
                <Input
                  name="taxaAdministracao"
                  control={control}
                  errors={errors}
                  validation={{
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Informe apenas números",
                    },
                  }}
                  className={!habilitarCustomizacaoTaxa ? "disabled" : ""}
                  disabled={!habilitarCustomizacaoTaxa}
                  placeholder="Taxa de administração"
                />
                {errors.taxaAdministracao &&
                  ValidationError(errors.taxaAdministracao)}
              </div>

              <div className="col-lg-8 d-flex align-items-end">
                <div className="form-check form-switch d-flex align-items-center ps-5">
                  <input
                    className="form-check-input mb-1"
                    style={{ width: "3.3em", height: "1.7em" }}
                    type="checkbox"
                    role="switch"
                    id="habilitarCustomizacaoTaxa"
                    name="habilitarCustomizacaoTaxa"
                    checked={habilitarCustomizacaoTaxa}
                    onChange={({ target: { checked } }) =>
                      setHabilitarCustomizacaoTaxa(checked)
                    }
                  />
                  <label
                    className="form-check-label ps-2"
                    htmlFor="habilitarCustomizacaoTaxa"
                  >
                    Habilitar customização de taxa
                  </label>
                </div>
              </div>
            </div>

            <div className="row g-3 mb-4">
              <div className="col-lg-12">
                <p className="text-500-dark-18 m-0">Escolha o número máximo de parcelas para compras com cartão de crédito</p>
              </div>
              <div className="col-lg-4">
                <select
                  className="form-select bc-input input-outlined p-2"
                  value={parcelamentoMaximo.toString()}
                  onChange={({ target: { value } }) =>
                    setParcelamentoMaximo(+value)
                  }
                >
                  {obterTotalParcelas().map((parcela) => (
                    <option key={parcela} value={parcela}>
                      {parcela}x
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row g-3 mb-4">
              <div className="col-lg-6 card-cinza-claro p-3">
                <p className="text-500-dark-18 mb-2">Portaria</p>
                <p className="text-400-black-16 m-0">
                  Login: {eventoModel.portariaAuthLogin}
                </p>
                <p className="text-400-black-16 mb-2">
                  Senha: {eventoModel.portariaAuthPassword}
                </p>
              </div>
            </div>
          </WhiteContainer>

          <WhiteContainer rowMarginBottom={4}>
            <p className="title-h1 text-black mb-3">Endereço do evento</p>

            {/*
              --------------------------------------------------------------------------
                                                  CEP                                   
              --------------------------------------------------------------------------
            */}
            <div className="row g-3 mb-3">
              <div className="col-lg-4">
                <p className="text-500-dark-18 mb-2">CEP</p>
                <Input
                  name="cep"
                  control={control}
                  errors={errors}
                  mask="99999-999"
                  validation={{
                    required: "Informe o CEP",
                    pattern: {
                      value: /^\d{5}-\d{3}$/,
                      message: "Informe um CEP válido",
                    },
                  }}
                  placeholder="CEP"
                />
                {errors.cep && ValidationError(errors.cep)}
              </div>
              <div className="col-lg-4 d-flex align-items-end my-2">
                <a
                  href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Não sei meu CEP
                </a>
              </div>
            </div>

            {/*
              --------------------------------------------------------------------------
                                          Link do google maps                           
              --------------------------------------------------------------------------
            */}
            <div className="row g-3 mb-3">
              <div className="col-lg-4">
                <p className="text-500-dark-18 mb-2">Link do google maps</p>
                <Input
                  name="linkSrcLocalizacaoMap"
                  control={control}
                  errors={errors}
                  validation={{}}
                  placeholder="Link do google maps"
                  className={!exibirLocalizacao ? "disabled" : ""}
                  disabled={!exibirLocalizacao}
                />
                {errors.linkSrcLocalizacaoMap &&
                  ValidationError(errors.linkSrcLocalizacaoMap)}
              </div>

              <div className="col-lg-8 d-flex align-items-end">
                <div className="form-check form-switch d-flex align-items-center ps-5">
                  <input
                    className="form-check-input mb-1"
                    style={{ width: "3.3em", height: "1.7em" }}
                    type="checkbox"
                    role="switch"
                    id="exibirLocalizacao"
                    name="exibirLocalizacao"
                    checked={exibirLocalizacao}
                    onChange={({ target: { checked } }) =>
                      setExibirLocalizacao(checked)
                    }
                  />
                  <label
                    className="form-check-label ps-2"
                    htmlFor="exibirLocalizacao"
                  >
                    Habilitar endereço
                  </label>
                </div>
              </div>
            </div>

            {fetchingCep && (
              <div className="row g-0 mb-3">
                <div className="col-lg-12">
                  <p className="text-500-dark-18 text-center mt-5 mb-2">
                    Buscando CEP...
                  </p>
                  <Loading container="30vh" />
                </div>
              </div>
            )}

            {buscouCepInvalido && (
              <div className="row g-0 mb-3">
                <div className="col-lg-12">
                  <p className="text-500-dark-18 text-center mt-5 mb-2">
                    Não foi possível obter o endereço.
                  </p>
                </div>
              </div>
            )}

            {naoBuscouCep && (
              <div className="row g-0 mb-3">
                <div className="col-lg-12">
                  <p className="text-500-dark-18 text-center mt-5 mb-2">
                    Informe o CEP para buscar o endereço.
                  </p>
                </div>
              </div>
            )}

            {buscouCepValido && (
              <>
                {/*
                  --------------------------------------------------------------------------
                                                  Logradouro                                
                  --------------------------------------------------------------------------
                */}
                <div className="row g-0 mb-3">
                  <div className="col-lg-12">
                    <p className="text-500-dark-18 mb-2">
                      Endereço (Logradouro)
                    </p>
                    <Input
                      name="logradouro"
                      control={control}
                      errors={errors}
                      validation={{
                        required: "Informe a rua",
                      }}
                      placeholder="Rua"
                    />
                    {errors.logradouro && ValidationError(errors.logradouro)}
                  </div>
                </div>

                {/* 
                  --------------------------------------------------------------------------
                                        Número / Complemento / Bairro                      
                  --------------------------------------------------------------------------
                */}
                <div className="row g-3 mb-3">
                  <div className="col-lg-3">
                    <p className="text-500-dark-18 mb-2">Número</p>
                    <Input
                      name="numero"
                      control={control}
                      errors={errors}
                      validation={{}}
                      placeholder="Número"
                    />
                    {errors.numero && ValidationError(errors.numero)}
                  </div>

                  <div className="col-lg-3">
                    <p className="text-500-dark-18 mb-2">Complemento</p>
                    <Input
                      name="complemento"
                      control={control}
                      errors={errors}
                      validation={{}}
                      placeholder="Complemento"
                    />
                    {errors.complemento && ValidationError(errors.complemento)}
                  </div>

                  <div className="col-lg-3">
                    <p className="text-500-dark-18 mb-2">Bairro</p>
                    <Input
                      name="bairro"
                      control={control}
                      errors={errors}
                      validation={{
                        required: "Informe o bairro",
                      }}
                      placeholder="Bairro"
                    />
                    {errors.bairro && ValidationError(errors.bairro)}
                  </div>
                </div>

                {/*
                  --------------------------------------------------------------------------
                                              País / UF / Cidade                            
                  --------------------------------------------------------------------------
                */}
                <div className="row g-3 mb-3">
                  <div className="col-lg-3">
                    <p className="text-500-dark-18 mb-2">País</p>
                    <select
                      name="pais"
                      className="form-select backstage-input"
                      style={{ height: 40 }}
                    >
                      <option value="brasil">Brasil</option>
                    </select>
                  </div>

                  <div className="col-lg-3">
                    <p className="text-500-dark-18 mb-2">Estado</p>
                    <select
                      className="form-select backstage-input"
                      style={{ height: 40 }}
                      value={estado}
                      onChange={({ target: { value } }) => setEstado(value)}
                    >
                      <option value="" disabled>
                        Selecione um estado
                      </option>
                      {EstadosBrasileiros.map((estado) => (
                        <option key={estado.sigla} value={estado.sigla}>
                          {estado.nome}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-lg-3">
                    <p className="text-500-dark-18 mb-2">Cidade</p>

                    <Input
                      name="cidade"
                      control={control}
                      errors={errors}
                      validation={{
                        required: "Informe a cidade",
                      }}
                      placeholder="Cidade"
                    />
                    {errors.cidade && ValidationError(errors.cidade)}
                  </div>
                </div>
              </>
            )}
          </WhiteContainer>

          {/*
            --------------------------------------------------------------------------
                            Botões: Cancelar Evento / Cancelar / Editar               
            --------------------------------------------------------------------------
          */}
          <div className="row mb-4">
            <div className="col-xxl-8">
              <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
                <div>
                  <button
                    type="button"
                    className="bc-btn bc-btn-light px-3 py-2 mb-4 mb-lg-0 me-xl-3 d-flex justify-content-center align-items-center"
                    style={{ width: 200, height: 40 }}
                    onClick={confirmarCancelarEvento}
                    disabled={loading}
                  >
                    Cancelar evento
                  </button>
                </div>

                <div className="d-flex flex-column flex-lg-row align-items-center justify-content-lg-evenly">
                  <button
                    type="button"
                    className="bc-btn bc-btn-light px-3 py-2 mb-3 mb-lg-0 me-lg-3 d-flex justify-content-center align-items-center"
                    style={{ width: 200, height: 40 }}
                    onClick={() => navigate(-1)}
                    disabled={loading}
                  >
                    Cancelar
                  </button>

                  <button
                    type="submit"
                    className={`bc-btn bc-btn-primary px-3 py-2 d-flex justify-content-center align-items-center ${
                      (loading || fetchingCep || buscouCepInvalido) &&
                      "disabled"
                    }`}
                    style={{ width: 200, height: 40 }}
                    disabled={loading || fetchingCep || buscouCepInvalido}
                  >
                    Editar evento
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}

      {/*
        --------------------------------------------------------------------------
                                    Confirmar edição                             
        --------------------------------------------------------------------------
      */}
      <PromptModal
        icon={<EditModalIcon />}
        title="Confirmar alteração"
        subtitle="Tem certeza que deseja alterar as informações do evento?"
        cancelText="Voltar para edição"
        cancelAction={() => setConfirmModalOpen(false)}
        confirmText="Confirmar alteração"
        confirmAction={editarEvento}
        close={() => setConfirmModalOpen(false)}
        isOpen={confirmModalOpen}
      />

      {/*
        --------------------------------------------------------------------------
                                      Cancelar evento                             
        --------------------------------------------------------------------------
      */}
      <PromptModal
        icon={<DangerModalIcon />}
        title="Cancelar o evento"
        subtitle="Tem certeza que deseja cancelar o evento? Após o cancelamento seu evento estará inativo."
        cancelText="Voltar para edição"
        cancelAction={() => setCancelEventModalOpen(false)}
        confirmText="Confirmar cancelamento"
        confirmAction={cancelarEvento}
        close={() => setCancelEventModalOpen(false)}
        isOpen={cancelEventModalOpen}
      />
    </MainLayout>
  );
}
